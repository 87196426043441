/** @format */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --primary: #4d4467;
  --secondary: #999999;
  --ctf-white: #ffffff;
  --ctf-black: #000000;
  --ctf-third: #dddddd;
  --ctf-fourth: #dddddd;
  --ctf-fifth: #417505;
  --ctf-sixth: #f5f7f8;
  --ctf-seventh: #f6a51f;
  --ctf-eighth: #eeeeee;
  --ctf-ninth: #3a3b3f;
  --ctf-danger: #d0021b;
  --ctf-dark-blue: #475569;

  /*font-size root*/
  --font-family: "Montserrat", sans-serif;
  --ctf-h1: 36px;
  --ctf-h2: 24px;
  --ctf-h3: 20px;
  --ctf-h4: 18px;
  --ctf-h5: 14px;
  --ctf-h6: 12px;
  --body-font-size: 12px;
  --es-radius: 5px;
}

@mixin transition {
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

@mixin sidepad {
  padding-left: 30px;
  padding-right: 30px;
}

@mixin box-shadow {
  box-shadow: 0 4px 4px 0 rgba(221, 221, 221, 0.1);
}

@mixin btn {
  background: var(--primary);
  border-radius: var(--es-radius);
  font-size: var(--ctf-h5);
  color: var(--ctf-white);
  border: 1px solid var(--primary);
  font-weight: bold;
  font-family: var(--font-family);
  text-transform: uppercase;
  padding: 12px;
  @include transition;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

/*scrollbar common css */
::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

::-webkit-scrollbar-track-piece {
  width: 13px;
}

::-webkit-scrollbar-thumb:vertical {
  background-color: var(--primary);
  width: 13px;
  padding: 5px;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:horizontal {
  background-color: var(--primary);
  border-radius: 15px;
  height: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*do not touch css */
body {
  margin: 0px;
  padding: 0px;
  background: var(--ctf-sixth);
  color: var(--ctf-black);
  font-size: var(--body-font-size);
  line-height: 15px;
  font-weight: 400;
  font-family: var(--font-family);

  &::-webkit-scrollbar {
    width: 13px;
  }
}

p {
  margin: 0px;
  padding: 0px;
  line-height: 15px;
  font-size: var(--body-font-size);
  font-weight: 400;
}

h1,
.h1 {
  font-size: var(--ctf-h1);
  line-height: 54px;
}

h2,
.h2 {
  font-size: var(--ctf-h2);
  line-height: 28px;
}

h3,
.h3 {
  font-size: var(--ctf-h3);
  line-height: 24px;
}

h4,
.h4 {
  font-size: var(--ctf-h4);
  line-height: 20px;
}

h5,
.h5 {
  font-size: var(--ctf-h5);
  line-height: 18px;
}

h6,
.h6 {
  font-size: var(--ctf-h6);
  line-height: 15px;
}

/*text color classes*/
.ctf-tx-primary {
  color: var(--primary);
}

.ctf-tx-secondary {
  color: var(--secondary);
}

.ctf-tx-third {
  color: var(--ctf-third);
}

.ctf-tx-fourth {
  color: var(--ctf-fourth);
}

.ctf-tx-fifth {
  color: var(--ctf-fifth);
}

.ctf-tx-size-fifth {
  font-size: 16px !important;
}

.ctf-tx-sixth {
  color: var(--ctf-sixth);
}

.ctf-tx-seventh {
  color: var(--ctf-seventh);
}

.ctf-tx-danger {
  color: var(--ctf-danger);
}

//== Media queries breakpoints

// Extra small screen / phone
$screen-phone: 480px !default;
// Small screen / tablet
$screen-tablet: 768px !default;
// Medium screen / desktop
$screen-desktop: 992px !default;
// Large screen / wide desktop
$screen-lg-desktop: 1200px !default;

/**/
/*background color classes*/
.ctf-bg-primary {
  background-color: var(--primary);
}

.ctf-bg-secondary {
  background-color: var(--secondary);
}

.ctf-bg-third {
  background-color: var(--ctf-third);
}

.ctf-bg-fourth {
  background-color: var(--ctf-fourth);
}

.ctf-bg-fifth {
  background-color: var(--ctf-fifth);
}

.ctf-bg-sixth {
  background-color: var(--ctf-sixth);
}

.ctf-bg-seventh {
  background-color: var(--ctf-seventh);
}

/*font-weight classes*/
.f-100 {
  font-weight: 100;
}

.f-300 {
  font-weight: 300;
}

.f-500 {
  font-weight: 500;
}

.f-700 {
  font-weight: 700;
}

.f-bold {
  font-weight: bold;
}

/*pointer scss*/
.c-pointer {
  cursor: pointer;
}

.border-bottom-1 {
  border-bottom: 1px solid var(--ctf-eighth);
}

.border-top-1 {
  border-top: 1px solid var(--ctf-eighth);
}

/*common btn css*/
.btn {
  &:hover {
    background-color: transparent;
    color: var(--primary);
    border-color: var(--primary);
  }

  @include btn;
}

.btn-transparent {
  background-color: transparent;
  color: var(--primary);
  border-color: var(--primary);

  &:hover {
    @include btn;
  }
}

.btn-grey {
  background-color: var(--secondary);
  color: var(--ctf-white);
  border-color: var(--secondary);

  &:hover {
    @include btn;
  }
}

.btn-dark-blue {
  background-color: #35005f;
  color: var(--ctf-white);
  border-color: #35005f;

  &:hover {
    @include btn;
  }
}

.list-container {
  max-width: 1310px;
}

.btn-grouped {
  font-size: 14px;
  margin-top: 2%;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  margin-left: 1%;
  width: 100%;
}

.label-radio {
  margin-right: 6dvb;
}

.radio-div {
  font-size: var(--ctf-h4);
  padding: 16px;
  height: 56px;
  outline: transparent !important;
  box-shadow: none;
  color: var(--ctf-black);
  font-weight: 500;
  // height: 62px;
  margin-top: 6%;
  margin-left: -0.5rem;
}

.fields-radio {
  margin-top: 6%;
}

.note-desc {
  text-overflow: ellipses;
  overflow: hidden;
  width: 400px;
  text-overflow: nowrap;
}

.note-list {
  list-style: none;
  padding: 0;
}

.note-item {
  position: relative;
  padding-right: 11px;
  margin-bottom: 33px;
  font-size: 16px;
  font-weight: 400;
}

.note-label {
  position: absolute;
  top: 0;
  right: 9rem;
  background-color: #f8f9fa;
  padding: 2px 6px;
  font-size: 14px;
}

.fa-comment-pen {
  background-color: red;
}

.alert-note {
  font-size: 19px;
  font-weight: 500;
}

.swal2-cancel {
  margin-right: 2.5rem;
}

.swal2-confirm {}

.note-delete-btn {
  position: absolute;
  top: 0;
  right: 5%;
  background-color: #f8f9fa;
  padding: 2px 6px;
  font-size: 14px;
}

.note-update-btn {
  position: absolute;
  top: 0;
  right: 7%;
  background-color: #f8f9fa;
  padding: 2px 6px;
  font-size: 14px;
}

.btn-dark-blue-transparent {
  background-color: transparent;
  color: #35005f;
  border-color: #35005f;

  &:hover {
    @include btn;
  }
}

.btn-with-img {
  &.btn-transparent {
    background-color: transparent;
    color: var(--primary);
    border-color: var(--primary);

    .block-1 {
      margin-right: 5px;

      svg {
        height: 20px;
        width: 20px;
        vertical-align: middle;

        path {
          fill: var(--primary);
        }
      }
    }

    &:hover {
      svg {
        path {
          fill: var(--ctf-white);
        }
      }

      @include btn;
    }
  }

  &.btn-danger {
    background-color: transparent;
    color: var(--ctf-danger);
    border-color: var(--ctf-danger);

    .block-1 {
      margin-right: 5px;

      svg {
        height: 20px;
        width: 20px;
        vertical-align: middle;

        path {
          fill: var(--ctf-danger);
        }
      }
    }

    &:hover {
      svg {
        path {
          fill: var(--ctf-white);
        }
      }

      background-color: var(--ctf-danger);
    }
  }
}

.btn-large {
  min-width: 380px;
}

.btn-medium {
  min-width: 200px;
}

.btn-small {
  min-width: 150px;
}

.btn-x-small {
  min-width: 50px;
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.btn-danger {
  background-color: transparent;
  color: var(--ctf-danger);
  border-color: var(--ctf-danger);

  &:hover {
    background-color: var(--ctf-danger);
    color: var(--ctf-white);
  }
}

.btn-dark {
  background-color: var(--ctf-black);
  color: var(--ctf-white);
  border-color: var(--ctf-black);

  &:hover {
    background-color: transparent;
    color: var(--ctf-black);
  }
}

.btn-green {
  background-color: var(--ctf-fifth);
  color: var(--ctf-white);
  border-color: var(--ctf-fifth);

  &:hover {
    background-color: transparent;
    color: var(--ctf-fifth);
    border-color: var(--ctf-fifth);
  }
}

.btn-yellow {
  background-color: var(--ctf-seventh);
  color: var(--ctf-black);
  border-color: var(--ctf-seventh);

  &:hover {
    background-color: transparent;
    color: var(--ctf-seventh);
    border-color: var(--ctf-seventh);
  }
}

/*form input css*/
.grey-background {
  color: rgb(0, 0, 0, 0.5);
}

.custom-inputs {
  position: relative;
  margin-bottom: 40px;

  label {
    position: absolute;
    top: -8px;
    left: 20px;
    background: var(--ctf-white);
    padding: 0 15px;
  }

  .form-control {
    font-size: var(--ctf-h4);
    padding: 16px;
    height: 56px;
    outline: transparent !important;
    box-shadow: none;
    color: var(--ctf-black);
    font-weight: 500;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--secondary);
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--secondary);
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: var(--secondary);
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: var(--secondary);
    }

    &:focus {
      border-color: var(--primary);
      outline: 0;
      box-shadow: none;
      border: 2px solid var(--primary);

      &+label {
        color: var(--primary);
      }
    }
  }

  #custom-file-input {
    height: 61px;
    text-align: center !important;
    padding-top: 12px;
    color: var(--ctf-ninth);
  }

  .multi-select-search-block {
    padding: 0;

    .css-yk16xz-control,
    .css-1pahdxg-control {
      padding: 0;
      height: 100% !important;
      min-height: 100% !important;
      outline: transparent;
      border-color: var(--primary);
    }

    .css-1pahdxg-control {
      border-color: var(--primary);
      box-shadow: 0 0 0 2px #7b16a3;

      &:focus,
      &:hover {
        box-shadow: 0 0 0 2px #7b16a3;
      }
    }
  }

  .PhoneInput {
    position: relative;
  }

  textarea.form-control {
    height: 300px;
  }

  .PhoneInputCountry {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0%, -50%);
  }

  .PhoneInputInput {
    font-size: var(--ctf-h4);
    padding: 16px;
    height: 56px;
    padding-left: 40px;
    outline: transparent !important;
    box-shadow: none;
    color: var(--ctf-black);
    font-weight: 500;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--secondary);
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--secondary);
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: var(--secondary);
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: var(--secondary);
    }

    &:focus {
      border-color: var(--primary);
      outline: 0;
      box-shadow: none;
      border: 2px solid var(--primary);

      &+label {
        color: var(--primary);
      }
    }
  }

  &.with-icon {
    .form-control {
      padding-right: 50px;
    }

    .icons {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 10px;
      cursor: pointer;

      svg {
        width: 25px;
        height: 25px;

        path {
          fill: var(--primary);
          @include transition;
        }

        rect {
          fill: var(--primary);
        }
      }

      &:focus {
        svg {
          path {
            fill: var(--ctf-seventh);
            @include transition;
          }

          rect {
            fill: var(--ctf-seventh);
          }
        }
      }

      &:hover {
        svg {
          path {
            fill: var(--ctf-seventh);
            @include transition;
          }

          rect {
            fill: var(--ctf-seventh);
          }
        }
      }
    }
  }

  &.error {
    .form-control {
      border-color: var(--ctf-danger);
      border-color: var(--ctf-danger);

      &+label {
        color: var(--ctf-danger);
      }
    }
  }
}

.input-remain-content {
  .input-title {
    padding: 16px;
    padding-left: 0px;
    word-break: break-all;
  }
}

/* common input file type css */
[type="file"]::-webkit-file-upload-button {
  -webkit-appearance: button;
  cursor: pointer;
  color: #6b6582;
  border-radius: 3px;
  border: none;
  padding: 5px 10px;
  margin-right: 15px;
}

/*block padding*/
.blk-padding-x {
  padding: 0 24px;
}

.blk-padding-y {
  padding: 24px 0px;
}

.blk-padding-x-y {
  padding: 24px;
}

ul,
li {
  padding: 0px;
  margin: 0;
  list-style-type: none;
}

a {
  color: var(--primary);

  &:hover {
    color: var(--ctf-seventh);
  }
}

.user-navbar {
  position: absolute;
  right: 100px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  min-height: fit-content;
  min-width: fit-content;
  overflow: hidden !important;

  .image-user-nav {
    margin-right: 12px;
  }

  .username-nav {
    h4 {
      margin-right: 21px;
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.logout-logo-nav {
  cursor: pointer;
}

.logout-icon-modal {
  display: flex;
  justify-content: center;
  margin: 20px 0px !important;
  width: 100%;
}

.logout-modal-text {
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    /* or 200% */

    text-align: center;

    color: #0f172a;
  }
}

/*header css*/
.full-layout {
  .navbar-block {
    .navbar {
      position: relative;
    }
  }
}

.navbar-block {
  width: 105%;

  .navbar {
    box-sizing: border-box;
    border: 1px solid var(--ctf-eighth);
    padding: 10px 40px;
    position: fixed;
    width: 100%;
    z-index: 3;
    top: 0px;
    @include box-shadow;

    .brand-logo {
      width: 50px;
    }

    button.navbar-toggler {
      outline: transparent;
      @include transition;

      &:focus,
      &:hover {
        background-color: var(--ctf-seventh);
      }
    }
  }
}

/*login pages*/
.login-format {
  .full-layout-body {
    height: calc(100vh - 86px);
    display: flex;
    align-items: center;

    .full-layout-body-inner-block {
      background-color: var(--ctf-white);
      box-shadow: 0 4px 4px 0 rgba(221, 221, 221, 0.1);
      padding: 50px;

      .form-title.full-layout-title {
        text-align: center;
        padding-bottom: 50px;
      }

      .forget-password-link {
        padding-bottom: 25px;
        text-align: right;
      }
    }
  }
}

/*sidebar css started*/
.sidebar {
  width: 300px;
  position: fixed;
  background-color: var(--ctf-white);
  height: calc(100vh - 79px);
  display: block;
  margin-top: 79px;
  top: 0px;
  z-index: 99;

  .close-icon-sidebar {
    cursor: pointer;
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;

    svg {
      path {
        fill: var(--primary);
      }
    }
  }

  @include transition;

  .sidebar-wrapper {
    height: calc(100% - 54px);
    overflow-y: auto;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0px;
    }

    .add-dropdown-menu {
      width: 100%;
      left: 0%;
      z-index: 561891561;

      .dropdown-item {
        display: flex;
        width: 100%;
        padding: 10px 15px;
        align-items: center;

        .block-1 {
          margin-right: 10px;
          width: 35px;
          display: inline-flex;
          align-items: center;

          svg {
            path {
              fill: var(--ctf-black);
            }

            g {
              opacity: 1;
            }
          }
        }

        .block-2 {
          width: calc(100% - 50px);
          display: inline-flex;
          align-items: center;
          font-size: var(--ctf-h5);
        }

        &:focus,
        &.active,
        &:active,
        &:hover {
          background-color: transparent;
          outline: transparent;
          color: var(--primary);
        }

        &:focus,
        &:active,
        &:active,
        &:hover {
          .block-1 {
            svg {

              path,
              circle {
                fill: var(--primary);
              }

              g {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    /*sidebar image block css*/
    .user-main-block {
      padding: 24px;
      @include sidepad;
      text-align: center;

      .user-img-block {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        margin: 0 auto;
        overflow: hidden;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .username {
        padding-top: 18px;
      }
    }

    .seperator {
      display: block;
      width: calc(100% - 30px);
      border-bottom: 1px solid var(--secondary);
      content: " ";
      margin: 0 auto;
    }

    .sidebar-dropdown {
      padding: 25px;
      @include sidepad;

      .dropdown-menu {
        will-change: unset !important;
        transform: none !important;
        top: 49px !important;
      }

      .custom-dropdown-button {
        font-size: var(--ctf-h5);
        background-color: var(--ctf-seventh);
        color: var(--ctf-white);
        border-color: transparent;
        outline: transparent !important;
        box-shadow: none;
        text-align: left;
        padding-left: 18px;

        &:after {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          right: 18px;
          border-top: 5px solid;
          border-right: 5px solid transparent;
          border-bottom: 0;
          border-left: 5px solid transparent;
        }
      }
    }

    /*side bar links list css started*/
    .list-ul {
      width: 100%;
      background-color: var(--ctf-white);
    }

    .list-main-block {
      padding-left: 0px;
      margin-bottom: 0;
    }

    .list-element {
      position: relative;
      display: block;
      background: transparent;
      width: 100%;

      .link-blk {
        text-decoration: none;
        color: var(--secondary);
        margin: 0 0 0 0;
        padding: 15px 0px;
        display: block;
        position: relative;
        @include sidepad;
        cursor: pointer;

        .link-data {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: bold;

          .link-img {
            display: inline-flex;
            vertical-align: top;
            width: 35px;
            padding-left: 0;
            transition: all 0.2s;

            svg {
              g {
                opacity: 1;
              }

              circle {
                fill: var(--secondary);
              }

              path {
                fill: var(--secondary);
              }
            }
          }

          .link-text {
            display: inline-flex;
            width: calc(100% - 50px);
          }
        }
      }

      &:hover,
      &:focus {
        .link-blk {
          text-decoration: none;
          color: var(--primary);

          &:before {
            position: absolute;
            width: 2px;
            height: 100%;
            background-color: var(--primary);
            display: block;
            content: " ";
            left: 0;
            top: 0;
          }

          .link-data {
            .link-img {
              svg {
                circle {
                  fill: var(--primary);
                }

                path {
                  fill: var(--primary);
                }
              }
            }
          }
        }
      }

      &.active {
        background-color: var(--ctf-sixth);

        .curve {
          position: absolute;
          top: -25px;
          width: 30px;
          height: 30px;
          background: var(--ctf-sixth);
          right: 0;
        }

        .curve-2 {
          position: absolute;
          bottom: -25px;
          width: 30px;
          height: 25px;
          background: var(--ctf-sixth);
          right: 0;
        }

        .list-element-upper-curve {
          width: 30px;
          height: 25px;
          background: var(--ctf-white);
          position: relative;
          border-radius: 0 0 47px;
        }

        .list-element-upper-curve-2 {
          width: 30px;
          height: 25px;
          background: var(--ctf-white);
          position: relative;
          border-radius: 0px 0px 0px 46px;
          transform: rotate(180deg);
        }

        .link-blk {
          text-decoration: none;
          color: #4d4467;

          &:before {
            position: absolute;
            width: 4px;
            height: 100%;
            background: #4d4467;
            // background-color: var(--primary);
            display: block;
            content: " ";
            left: 0;
            top: 0;
            border-radius: 0 20px 20px 0;
          }

          .link-data {
            .link-img {
              svg {
                circle {
                  fill: #4d4467;
                }

                path {
                  fill: #4d4467;
                }
              }
            }
          }
        }
      }

      .dropdown {
        overflow: hidden;

        .list-first-level-menu {
          position: relative !important;
          width: 100%;
          top: 0px !important;
          left: 0px !important;
          transform: none !important;
          background-color: transparent;
          border-color: transparent;

          .dropdown-item {
            display: block;
            width: 100%;
            padding: 15px 30px;
            clear: both;
            font-weight: 400;
            color: var(--secondary);
            outline: none !important;
            display: block;
            width: 100%;
            padding: 15px 0px 15px 65px;
            font-size: var(--ctf-h5);
            font-weight: 500;

            a {
              color: var(--secondary);
              text-decoration: none;
            }

            &:active {
              background-color: transparent;
            }

            &.active {
              background-color: transparent;
              color: var(--primary);
              position: relative;

              a {
                color: var(--primary);
                text-decoration: none;
              }

              &:before {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%) rotate(-90deg);
                left: 33px;
                border-top: 5px solid;
                border-right: 5px solid transparent;
                border-bottom: 0;
                border-left: 5px solid transparent;
                content: " ";
              }
            }
          }
        }
      }
    }
  }

  .out-block {
    font-size: 14px;
    font-weight: bold;
    @include sidepad;

    .link-main-block {
      padding: 15px 0px;
      display: block;
      text-decoration: none;
      color: var(--secondary);

      .link-img {
        display: inline-flex;
        vertical-align: top;
        width: 35px;
        padding-left: 0;
        transition: all 0.2s;

        svg {
          path {
            fill: var(--secondary);
          }
        }
      }

      .content {
        display: inline-flex;
        width: calc(100% - 50px);
      }

      &:hover,
      &:focus {
        color: var(--primary);

        .link-img {
          svg {
            path {
              fill: var(--primary);
            }
          }
        }
      }
    }
  }
}

/*admin layout body*/
.admin-layout-body {
  margin-left: 300px;
  margin-top: 86px;
  width: calc(100% - 300px);
  background-color: var(--ctf-sixth);
  height: calc(100vh - 86px);
  display: block;
  top: 0;
}

.full-width-layout {
  width: calc(100% - 88px);
  margin-left: 88px;
}

.partial-width-layout {
  width: calc(100% - 300px);
  margin-left: 300px;
}

.panel {
  padding: 25px 50px;
}

/*export-dropdown component css*/
.custom-dropdown {
  .dropdown-toggle {
    outline: none;
    box-shadow: none;
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--ctf-white);
    @include transition;
    font-size: var(--ctf-h5);
    padding: 12px 18px;

    &:hover {
      color: var(--primary);
      border-color: var(--primary);
      background-color: transparent;
    }
  }

  &.map-list-drp {
    .dropdown-toggle {
      background-color: var(--ctf-white);
      border-color: var(--secondary);
      color: var(--secondary);
    }
  }
}

.custom-dropdown-qa {
  margin-right: 0.5vw;

  .dropdown-toggle {
    outline: none;
    box-shadow: none;
    background-color: var(--ctf-white);
    border-color: var(--primary);
    color: var(--primary);
    @include transition;
    font-size: var(--ctf-h5);
    padding: 12px 18px;

    &:hover {
      color: var(--ctf-white);
      border-color: var(--ctf-white);
      background-color: var(--primary);
    }
  }

  &.map-list-drp {
    .dropdown-toggle {
      background-color: var(--ctf-white);
      border-color: var(--secondary);
      color: var(--secondary);
    }
  }
}

.collapsed-dropdown {
  border: none !important;
  background-color: orange;
  background: orange;
  position: relative;
  right: 15px;
  display: flex;
  justify-content: center;

  &:after {
    border: none !important;
  }

  img {
    position: relative;
    left: 2px;
  }
}

/**dashboard css started*/
.export-data-block {
  padding-bottom: 25px;
}

.information-block {
  .data-block {
    .block-2 {
      display: inline-block;
      width: 80px;
      height: 80px;
      vertical-align: text-top;
      background-color: var(--primary);
      border-radius: 50%;
      padding: 15px;

      svg {
        width: 100%;
        height: 100%;

        g {
          opacity: 1;
        }

        path,
        circle {
          fill: var(--ctf-white);
        }
      }
    }

    .block-1 {
      display: inline-block;
      width: calc(100% - 80px);
      vertical-align: text-top;
    }
  }
}

.card-panel {
  background-color: var(--ctf-white);
  padding: 24px;
}

.note-details {
  font-size: 17px;
  line-height: 1.5;
  color: grey;
}

/*tittle css started*/
.info-title {
  padding-bottom: 20px;
  color: grey;

  &.with-border {
    border-bottom: 1px solid var(--ctf-eighth);
  }
}

.info-product-title {
  padding-bottom: 20px;
  color: black;

  &.with-border {
    border-bottom: 1px solid var(--ctf-eighth);
  }
}

/*info*/
.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;

  .img-blk {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.product-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;

  .img-blk {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.image-profile-link {
  width: 120px;
  height: 120px;

  background-color: #F1F5F9;
  ;

}

.file-input-link .file-input {
  position: relative;

  .upload-link {
    text-transform: uppercase;
  }

  .upload-link-product {
    // text-transform: uppercase;
    /* Adjust the font size as needed */
    text-decoration: underline;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #4D4467;
  }



  input {
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    width: 140px;
    position: absolute;
    top: 0px;
  }
}

.links {
  display: inline-block;
  width: auto;
  padding: 15px;
  cursor: pointer;

  &:hover {
    color: var(--primary);
  }
}

/*capsual scss*/
.capsual-list {
  .capsual {
    display: inline-block;
    padding: 10px 15px;
    // padding: 3px 34px 9px 15px;
    background: var(--ctf-sixth);
    border-radius: 15px;
    margin: 10px;
    max-width: 250px;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;

    &:first-child {
      margin-left: 0px;
    }

    .capsual-p {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .capsual-delete {
      position: absolute;
      top: -5px;
      right: -5px;
      transform: translate(0%, 50%);
    }
  }
}

/*checkbox scss started*/
.selction-box {
  .form-check-input {
    position: absolute;
    margin-top: 8px;
    margin-left: -1.25rem;
  }

  input[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
  }

  .form-check {
    position: relative;
    display: block;
    padding-left: 0;
  }

  .checkbox-custom {
    .form-check-input {
      position: absolute;
      margin-top: 8px;
      margin-left: -1.25rem;
    }

    input[type="checkbox"] {
      cursor: pointer;
      opacity: 0;
    }

    .form-check {
      position: relative;
      display: block;
      padding-left: 0;
    }
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 18px;
    border-radius: 3px;
    width: 18px;
    border: 1px solid #888888;
    background-color: transparent;
    transform: translate(0, -50%);

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  .container-blk {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    line-height: 23px;
    cursor: pointer;
    font-size: 18px;
    text-transform: capitalize;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      input {
        ~ {
          .checkmark {
            background-color: transparent;
          }
        }
      }
    }

    input {
      &:checked {
        ~ {
          .checkmark {
            background-color: var(--primary);

            &:after {
              display: block;
            }
          }
        }
      }
    }

    .checkmark {
      &:after {
        left: 5px;
        top: 1.5px;
        width: 6px;
        height: 10px;
        border: solid var(--ctf-sixth);
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}

.selction-box-new {
  .form-check-input {
    position: absolute;
    margin-top: 8px;
    margin-left: -1.25rem;
  }

  input[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
  }

  .form-check {
    position: relative;
    display: block;
    padding-left: 0;
  }

  .checkbox-custom {
    .form-check-input {
      position: absolute;
      margin-top: 8px;
      margin-left: -1.25rem;
    }

    input[type="checkbox"] {
      cursor: pointer;
      opacity: 0;
    }

    .form-check {
      position: relative;
      display: block;
      padding-left: 0;
    }
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 18px;
    border-radius: 3px;
    width: 18px;
    border: 1px solid #888888;
    background-color: transparent;
    transform: translate(0, -50%);

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  .container-blk {
    display: block;
    position: relative;
    padding-left: 25px;
    line-height: 23px;
    cursor: pointer;
    font-size: 18px;
    text-transform: capitalize;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      input {
        ~ {
          .checkmark {
            background-color: transparent;
          }
        }
      }
    }

    input {
      &:checked {
        ~ {
          .checkmark {
            background-color: var(--primary);

            &:after {
              display: block;
            }
          }
        }
      }
    }

    .checkmark {
      &:after {
        left: 5px;
        top: 1.5px;
        width: 6px;
        height: 10px;
        border: solid var(--ctf-sixth);
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}

.priviledge-checkbox-list-block {
  .priviledge-checkbox-element {
    margin: 7px 0;
    display: inline-block;
    width: 33.33%;
  }
}

/*custom switch box started*/
.switch-wrapper {
  margin: 10px;
  display: inline-block;
  float: left;
}

.switch-input {
  display: none;

  &:checked {
    + {
      .switch-label {
        background-color: var(--ctf-fifth);
        transition: all 0.3s ease-in 0s;

        &:before {
          right: 13px;
          border-color: var(--ctf-white);
        }
      }
    }
  }
}

.switch-label {
  display: inline-block;
  width: 35px;
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: var(--secondary);
  position: relative;
  border-radius: 100px;
  top: 8px;

  &:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: var(--ctf-white);
    border: 1px solid var(--secondary);
    border-radius: 100px;
    position: absolute;
    top: 50%;
    bottom: 0;
    right: 29px;
    transform: translate(10px, -50%);
    cursor: pointer;
  }
}

/* ====================================
   line-switch style
 =======================================*/
/**/
.line-switch {
  .switch-label {
    height: 15px;
    cursor: pointer;

    &:before {
      top: 50%;
    }
  }

  .switch-input {
    &:checked {
      + {
        .switch-label {
          &:before {
            background-color: var(--ctf-white);
          }
        }
      }
    }
  }
}

/*profile btn block*/
.action-btn-block {
  .btn {
    margin: 0 15px;

    &:first-child {
      margin: 0 15px 0 0px;
    }
  }
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

// Freeze modal
.freeze-date-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 35vh;

  form {
    width: 100%;
  }

  #freeze-date-picker-formik {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 23vh;
    width: 100%;
  }

  .custom-inputs {
    margin: 0 auto;
    width: 80%;
  }
}

.action-btn-block {
  margin-top: 35px;
}

/*profile date  */
.date-picker-wrapper {
  display: flex;
  align-items: center;

  .date-title-block {
    margin-right: 15px;
  }

  .start-date {
    margin-right: 25px;
    display: flex;
    position: relative;

    &.border {
      border: 1px solid #f3dbdb;
      border-radius: 5px;
    }

    .invalid-feedback {
      position: absolute;
      bottom: -15px;
      left: 10px;
    }
  }

  .end-date {
    display: flex;
    position: relative;

    &.border {
      border: 1px solid #f3dbdb;
      border-radius: 5px;
    }

    .invalid-feedback {
      position: absolute;
      bottom: -15px;
      left: 10px;
    }
  }

  .date-picker-img {
    padding-left: 10px;
  }

  .date-show {
    padding: 10px 30px;
    background-color: var(--ctf-sixth);
    border-radius: 15px;
    border-color: transparent;
    font-size: var(--ctf-h5);
    font-weight: 700;
    outline: transparent;
  }

  .react-datepicker__input-container {
    input {
      height: 40px;
      padding: 10px 30px;
      background-color: var(--ctf-sixth);
      border-radius: 15px;
      border-color: transparent;
      font-size: var(--ctf-h5);
      font-weight: 700;
      outline: transparent;
    }
  }
}

.timezone {
  min-width: 150px;

  input {
    border-radius: 15px;
  }

  .invalid-feedback {
    position: absolute;
    bottom: -20px;
    left: 10px;
  }
}

/*common time input*/
.time-input {

  &.form-control.is-invalid,
  .was-validated .form-control:invalid {
    background-position: right 45px center;
  }
}

/*common table filter css*/
.table-filter-header {
  .filters-main-wrapper {
    .filter-list-wraper {
      display: flex;
      align-items: flex-end;

      .filter-list-element {
        margin: 0 15px;
        display: inline-flex;

        &:first-child {
          margin-left: 0px;
        }

        &:last-child {
          margin-right: 0px;
        }
      }

      .form-control {
        font-size: var(--ctf-h5);
        height: 47px;
      }

      .search-block {
        .search-input-filter {
          .form-control {
            background-color: var(--ctf-sixth);
            outline: none;
            box-shadow: none !important;
            padding-left: 40px;

            &:focus {
              border-color: var(--primary);
            }
          }

          .search-icon {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 10px;
          }
        }
      }

      .select-filter-block {
        .select-filter {
          .select-filter-wrapper {
            position: relative;

            select {
              padding-left: 40px;
              background-image: linear-gradient(45deg,
                  transparent 50%,
                  var(--secondary) 60%),
                linear-gradient(135deg, var(--secondary) 40%, transparent 50%) !important;
              background-image: linear-gradient(45deg,
                  transparent 50%,
                  var(--secondary) 60%),
                linear-gradient(135deg, var(--secondary) 40%, transparent 50%) !important;
              background-position: calc(100% - 25px) 21px,
                calc(100% - 20px) 21px, 100% 0;
              background-size: 5px 5px, 5px 5px;
              background-repeat: no-repeat;
              -webkit-appearance: none;
              -moz-appearance: none;
              outline: transparent;
              box-shadow: none;
              height: 47px;
              cursor: pointer;

              &:focus {
                border-color: var(--primary);
              }
            }

            .search-filter-img {
              position: absolute;
              top: 50%;
              transform: translate(0, -50%);
              left: 10px;
            }
          }
        }
      }

      .filter-img {
        width: 40px;
        background: var(--ctf-sixth);
        height: 47px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        margin: 0 auto;
        border-radius: 5px;
        cursor: pointer;

        &.active {
          svg {
            path {
              fill: var(--primary);
            }
          }
        }
      }
    }
  }

  .other-filter {
    display: none;

    &.active {
      display: block;
    }

    .selction-box .form-check {
      display: inline-flex;
    }

    .other-filter-list-element {
      margin: 7px 15px;
      display: inline-block;
    }
  }
}

/*table css started*/
.bg {
  background-color: #f51111b3;
}

.table-main-block {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid var(--ctf-fourth);
  margin-bottom: 0px;

  .selction-box .form-check-input {
    margin-top: 0px;
  }

  .selction-box .checkmark {
    top: -15px;
    left: 10px;
    height: 18px;
    border-radius: 3px;
    width: 18px;
    border: 1px solid #888888;
    background-color: transparent;
    transform: none;
  }

  // .table-hover {
  //   tbody {
  //     tr {
  //       &:hover {
  //         background-color: #c3c3c3 !important;
  //       }
  //     }
  //   }
  // }

  .table {
    thead {
      background-color: var(--ctf-white);

      th {
        vertical-align: middle;
        height: 60px;
        border-color: var(--ctf-eighth);
        font-size: 14px;
        letter-spacing: -0.05px;
        line-height: 20px;
        color: var(--ctf-ninth);
        font-weight: 400;
        min-width: 120px;
        border-top: 0px;

        &.small {
          min-width: 50px;
          max-width: 50px;
          width: 50px;
        }

        &.medium {
          min-width: 82px;
        }

        &.semi-medium {
          min-width: 65px;
        }

        &.large {
          min-width: 100px;
        }

        &.large-extra {
          min-width: 160px;
        }
      }
    }

    td {
      vertical-align: middle;
      height: 60px;
      border-color: var(--ctf-eighth);
      font-size: 12px;
      letter-spacing: -0.05px;
      line-height: 20px;
      color: var(--ctf-black);
      font-weight: 600;

      &.txt-center {
        text-align: center;
      }

      .block-1 {
        width: 35px;
        height: 35px;
        display: inline-flex;
        border-radius: 50%;
        margin-right: 10px;
        position: relative;

        img {
          border-radius: 50%;
        }

        &:before {
          position: absolute;
          width: 8px;
          height: 8px;
          background-color: transparent;
          border-radius: 50%;
          display: block;
          right: 0px;
          top: 0px;
          content: " ";
        }

        &.active {
          &:before {
            background-color: var(--ctf-fifth);
          }
        }

        &.non-active {
          &:before {
            background-color: var(--ctf-danger);
          }
        }
      }

      .block-2 {
        width: calc(100% - 60px);
        height: 100%;
        display: inline-flex;
        align-self: center;
      }

      .block-3 {
        width: 7px;
        height: 35px;
        display: inline-flex;
        border-radius: 50%;
        margin-right: 10px;
        position: relative;

        &:before {
          position: absolute;
          width: 8px;
          height: 8px;
          background-color: transparent;
          border-radius: 50%;
          display: block;
          right: 0px;
          top: 0px;
          content: " ";
        }

        &.active {
          &:before {
            background-color: var(--ctf-fifth);
          }
        }

        &.non-active {
          &:before {
            background-color: var(--ctf-danger);
          }
        }
      }
    }

    &.table-striped {
      tbody {
        tr {
          &:nth-of-type(odd) {
            background-color: var(--ctf-sixth);
          }

          &:nth-of-type(even) {
            background-color: var(--ctf-white);
          }

          td {
            .edit-action {
              cursor: pointer;

              &:hover {
                svg {
                  g {
                    opacity: 1;
                  }

                  path {
                    fill: var(--primary);
                  }
                }
              }
            }

            .view-action {
              cursor: pointer;

              &:hover {
                svg {
                  g {
                    opacity: 1;
                  }

                  path {
                    fill: var(--ctf-seventh);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//wod-table
.wod-td {
  height: 150px !important;
  color: var(--primary) !important;
  font-size: var(--ctf-h3) !important;
}

.add-wod-td {
  color: var(--primary) !important;
  font-size: var(--ctf-h3) !important;
}

/*table-pagination css started*/
.pagination-inner-block {
  padding: 15px 30px;
  background-color: var(--ctf-white);
  border-top: 1px solid var(--ctf-third);
  display: inline-flex;
  align-items: center;
  width: 100%;

  .status-data-block {
    .status-block {
      .status-wrapper {
        display: inline-block;
        margin-left: 15px;

        &:first-child {
          margin-left: 0px;
        }

        .dot-wrapper {
          position: relative;
          padding-left: 25px;

          &:before {
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: transparent;
            border-radius: 50%;
            display: block;
            top: 50%;
            left: 5px;
            content: " ";
            transform: translate(0, -50%);
          }

          &.active {
            &:before {
              background-color: var(--ctf-fifth);
            }
          }

          &.non-active {
            &:before {
              background-color: var(--ctf-danger);
            }
          }
        }
      }
    }
  }

  .pagination-wrapper {
    margin-left: auto;
  }

  .row-select {
    select {
      min-width: 70px;
      padding-left: 15px;
      background-image: linear-gradient(45deg,
          transparent 50%,
          var(--secondary) 60%),
        linear-gradient(135deg, var(--secondary) 40%, transparent 50%) !important;
      background-image: linear-gradient(45deg,
          transparent 50%,
          var(--secondary) 60%),
        linear-gradient(135deg, var(--secondary) 40%, transparent 50%) !important;
      background-position: calc(100% - 25px) 13px, calc(100% - 20px) 13px,
        100% 0;
      background-size: 5px 5px, 5px 5px;
      background-repeat: no-repeat;
      -webkit-appearance: none;
      -moz-appearance: none;
      outline: transparent;
      box-shadow: none;
      border-color: transparent;
      font-size: var(--body-font-size);
      cursor: pointer;

      // height: 40px;
      &:focus {
        border-color: var(--primary);
      }
    }

    .search-filter-img {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 10px;
    }
  }

  .row-par-element {
    display: inline-flex;
    padding-left: 20px;
    color: var(--secondary);
  }

  .chevron-block {
    display: inline-flex;

    .chevron-list-element {
      display: inline-flex;

      a {
        svg {
          g {
            opacity: 1;

            &.arrow {
              fill: var(--secondary);
            }
          }
        }

        &:hover,
        &:focus,
        &:active,
        &.active {
          svg {
            g.arrow {
              fill: var(--ctf-black);
            }
          }
        }
      }
    }
  }
}

/*social media link page*/
.social-link-input {
  .form-control {
    padding-left: 50px;
  }

  .social-link-img {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0, -50%);
  }
}

/*delete pop up*/
.delete-pop {
  .modal-body-content {
    padding: 15px;
    text-align: center;

    .delete-title {
      padding: 20px 0px;
    }

    .delete-data {
      padding-bottom: 20px;
    }
  }
}

.owl-stage-outer {
  overflow: visible !important;
}

/*trainer page css started*/
.plan-list-block {
  overflow: visible !important;

  .plan-list-element {
    overflow: visible !important;

    display: inline-flex;
    // width: 25%;
    padding: 22px 22px 50px 22px;
    border-radius: 5px;
    // background-image: url("../images/plan-bg.svg");
    // background-size: 50px;
    // background-position: top right;
    // background-repeat: no-repeat;
    background-color: var(--ctf-sixth);

    .plan-date-title {
      overflow: visible !important;

      display: flex;
      background: #ffffff;
      border-radius: 5px;
      width: 100%;
      height: 35px;
      align-items: center;
      justify-content: center;
      margin: 29px auto;

      span {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
      }
    }

    .plan-desc {
      background-color: white;
      border-radius: 5px;
      padding: 12px;
      margin-top: 12px;
    }

    .plan-title {
      .three-dots-menu {
        position: relative;
        bottom: 10px;
        left: 10px;

        .threeDotsContaier {
          background-color: transparent !important;
          background: transparent !important;
          border: none !important;
          height: 45px;
          padding: 0px;

          img {
            height: 45%;
          }
        }

        .threeDotsContaier::after {
          visibility: hidden !important;
        }

        .threeDotsContaier::focus {
          box-shadow: none !important;
          border: none;
        }

        .dropDownMenuContainer {
          // max-height: 190px;
          overflow-y: scroll;
          border: none;
          // left: -200px;
          top: 10px !important;
          z-index: 8000;
          overflow: visible !important;
          box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
          border-radius: 5px;

          .dropdown-divider {
            margin: 0.5rem 24px;
          }

          .dropdown-divider:last-child {
            display: none !important;
          }

          .dropDownItem {
            display: flex;
            width: 100%;
            justify-content: start;
            padding: 18px !important;

            .dropDownTitle {
              margin-left: 19px;
              font-size: 14px;
            }
          }
        }

        .btn-secondary:focus {
          box-shadow: none;
        }
      }

      .plan-img {
        height: 64px;
        display: flex;
        align-items: top;
        width: 100%;
        margin-right: 10px;

        .package-logo-avatar {
          display: inline-flex;
          border-radius: 50%;
          width: 64px;
          background: white;
          justify-content: center;
          align-items: center;
          margin-right: 16px;

          img {
            width: 30px !important;
            margin: 0 !important;
          }
        }
      }

      .plan-block-2 {
        display: inline-block;
        width: calc(100% - 45px);
      }
    }
  }
}

.member-page-sub-cards {
  min-height: 332px;
}

.package-name-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0px;

  p {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 700;
    font-size: 16px;
    /* identical to box height, or 150% */

    color: #0f172a;
  }
}

.twohun {
  left: -200px !important;
}

.forty {
  left: -100px !important;
}

.pack-outer-container:nth-child(2) {
  .dropDownMenuContainer {
    // left: -40px !important;
  }
}

@media screen and (min-width: 1200px) {
  .package-name-text p {
    max-width: 150px;
  }
}

@media screen and (max-width: 768px) {
  .twohun {
    left: -100px !important;
  }

  .forty {
    left: -100px !important;
  }

  .package-card-status {
    position: absolute;
    top: 90px;
    right: 27px;
  }

  .package-name-text p {
    max-width: 150px;
  }

  .package-name-text {
    margin-bottom: 14px;
  }
}

.package-price {
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    color: #0f172a;

    mix-blend-mode: normal;
  }
}

.package-card-status {
  border-radius: 4px;
  color: white;
  min-width: fit-content;
  width: 71px !important;
  display: inline-flex;
  justify-content: center;
  margin-left: 16px;

  .package-status-text {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    padding: 8px 16px;
  }
}

.status-active {
  background: #19871d;
}

.status-inactive {
  background: #e3281c;
}

.bg-grey {
  background-color: #f5f7f8 !important;
}

.selected-classes-box {
  .bg-one {
    background-color: white;
  }

  background-color: "red";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  min-height: 230px;
  background: #f5f7f8;
  border-radius: 4px;
  border: 1px solid #e4e7eb;
overflow-y: auto;
  // flex-wrap: nowrap !important;

  .selected-class-row {
    border: 0.5px solid #e4e7eb;
    width: 90%;

    .selected-class-name {
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
      }
    }

    .selected-class-actions-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      input::placeholder {
        font-size: 13px;
      }

      path {
        fill: rgb(0, 0, 0, 0.4);
      }
    }
  }

  .no-classes-message {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    opacity: 0.5;
    margin: auto 0;
  }
}

/*sucess full block*/
.success-block {
  position: fixed;
  top: 85px;
  left: 300px;
  width: calc(100% - 300px);
  padding: 17px;
  background-color: var(--ctf-fifth);
  z-index: 9;
  border-radius: 0 0 10px 10px;
  @include transition;
}

.subscription-plan-list-element {
  display: inline-block;

  .custom-btn {
    background-color: #ffffff;
    padding: 6px 25px;
    border-radius: 10px;
    margin-top: 15px;
    float: left;

    input[type="checkbox"] {
      display: none;
      cursor: pointer;
    }

    label {
      color: var(--secondary);
      font-weight: normal;
      position: relative;
      padding-left: 35px;
      font-size: 16px;
      margin-bottom: 0;
      width: 100%;
    }

    input[type="radio"] {
      &:checked {
        + {
          label {
            &:after {
              border-radius: 11px;
              width: 12px;
              height: 12px;
              position: absolute;
              left: 4px;
              content: " ";
              display: block;
              background: var(--primary);
              top: 50%;
              transform: translate(0, -50%);
            }
          }
        }
      }
    }

    input[type="checkbox"] {
      &:checked {
        + {
          label {
            &:after {
              border-radius: 11px;
              width: 12px;
              height: 12px;
              position: absolute;
              left: 4px;
              content: " ";
              display: block;
              background: var(--primary);
              top: 50%;
              transform: translate(0, -50%);
            }
          }
        }
      }
    }
  }

  .label-blk {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .custom-btn-radio {
    background-color: #ffffff;
    padding: 6px 25px;
    border-radius: 10px;
    margin-top: 15px;

    input[type="radio"] {
      display: none;
    }

    label {
      color: var(--secondary);
      font-weight: normal;
      position: relative;
      padding-left: 35px;
      font-size: 16px;
      margin-bottom: 0;
      width: 100%;

      &:before {
        content: " ";
        display: inline-block;
        position: absolute;
        margin: 0 5px 0 0;
        width: 20px;
        height: 20px;
        border-radius: 11px;
        border: 2px solid var(--primary);
        background-color: transparent;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    input[type="radio"] {
      &:checked {
        + {
          label {
            &:after {
              border-radius: 11px;
              width: 12px;
              height: 12px;
              position: absolute;
              left: 4px;
              content: " ";
              display: block;
              background: var(--primary);
              top: 50%;
              transform: translate(0, -50%);
            }
          }
        }
      }
    }
  }
}

/*dashboard scss started*/
.dash-board-import-panel {
  height: calc(100vh - 86px);

  .import-data-content {
    padding: 50px 0px;
  }

  .import-btn {
    padding-top: 50px;
  }
}

/**/
.custom-dropdown-block {
  .sub-title {
    padding: 10px;
  }

  .dropdown-item {
    padding: 10px 10px;
    outline: none;

    span {
      margin-right: 10px;
    }

    &.active,
    &:active {
      color: var(--primary);
      text-decoration: none;
      background-color: transparent;
    }
  }
}

/*accessibility table*/
/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .accordion {
    background-color: var(--ctf-sixth);
    cursor: pointer;
    padding: 18px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
    position: relative;
    font-weight: 700;
    border-radius: 5px;

    .accordion__title {
      font-size: var(--ctf-h4);
      font-weight: 500;
      padding-right: 25px;
      text-align: left;
    }

    .accordian-plus {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%);
      transition: 0.6s ease;
    }

    &:hover {
      background-color: var(--primary);

      .accordion__title {
        color: var(--ctf-white);
      }

      .accordian-plus {
        svg {
          path {
            fill: var(--ctf-white);
          }
        }
      }
    }

    &.active {
      background-color: var(--primary);

      .accordion__title {
        color: var(--ctf-white);
      }

      .accordian-plus {
        transform: translate(0, -50%) rotate(45deg);
        transition: 0.6s ease;

        svg {
          path {
            fill: var(--ctf-white);
          }
        }
      }
    }
  }

  .accordion__content {
    background-color: var(--ctf-white);
    overflow: hidden;
    transition: max-height 0.6s ease;
    border: 1px solid var(--ctf-third);

    @include box-shadow;
  }

  .accordion__text {
    font-size: var(--body-font-size);
    padding: 18px;
  }
}

/*dashboard pie chart*/
.chart-panel {
  background-color: var(--ctf-white);
  height: 100%;
  transition: box-shadow 1s;
  // box-shadow: none|h-offset v-offset blur spread color |inset|initial|inherit;

  .pie-char-block {
    width: 80px;
    height: 80px;
    margin: 35px auto;
  }
}

.chart-panel:hover {
  cursor: pointer;
  box-shadow: 0px 0px 5px 5px #f6a51f inset;
}

.description-content-element {
  padding: 15px 0px;

  .color-box {
    display: block;
    width: 15px;
    height: 15px;
    background-color: transparent;
    content: " ";
    display: inline-flex;
    margin-right: 10px;
    vertical-align: text-top;
    border-radius: 5px;
  }

  .color-text {
    display: inline-flex;
    flex-direction: column;
    width: calc(100% - 35px);
  }

  .conetnt-paragraph {
    font-size: var(--body-font-size);
  }
}

/*text-editor css started*/
.text-editor-main-block {
  padding: 15px;

  .input-block {
    margin: 30px 0;
  }

  .rdw-editor-wrapper {
    box-sizing: content-box;

    .notranslate.public-DraftEditor-content {
      border: 1px solid var(--ctf-third);
      padding: 10px;
      min-height: 250px;
    }
  }
}

/*text-editor css ended*/

.line-chart {
  padding: 50px;
  border-radius: 30px;
  margin-bottom: 30px;

  .content-wrapper {
    padding: 15px 0px;
  }
}

.error-page-img {
  width: 60px;
}

.under-construction-img {
  width: 200px;
}

/**/
.gym-location-inner-wrapper {
  .search-block {
    .search-input-filter {
      .form-control {
        background-color: var(--ctf-sixth);
        outline: none;
        box-shadow: none !important;
        padding-left: 40px;

        &:focus {
          border-color: var(--primary);
        }
      }

      .search-icon {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 10px;
      }
    }
  }
}

.map-view {
  overflow-x: hidden;
  width: 100%;
  height: 220px;
  position: relative;
  border-radius: 15px;
}

.members-list-block {
  .table-main-block {
    .table {
      td {
        .block-1 {

          // width: 0px;
          &:before {
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
          }
        }

        .block-3 {

          // width: 0px;
          &:before {
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
          }
        }

        .member-status {
          &:before {
            right: 0;
            top: 0;
            transform: translate(0, 0);
          }
        }
      }
    }
  }
}

.members-badge {
  .block-1 {
    img {
      width: 35px;
      height: 35px;
      margin-inline-end: 15px;
      border-radius: 50%;
    }

    &:before {
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: transparent;
      border-radius: 50%;
      display: block;
      left: 42px;
      top: 7px;
      content: " ";
    }

    &.active {
      &:before {
        background-color: var(--ctf-fifth);
      }
    }

    &.non-active {
      &:before {
        background-color: var(--ctf-danger);
      }
    }
  }

  .block-3 {

    // width: 0px;
    &:before {
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}

.rdt_ExpandableRow {
  &:nth-of-type(odd) {
    background-color: #f2f2f2;
  }
}

/*adding pop up changes */
.step-block {
  .step-wrapper {
    .step {
      width: 50%;
      display: inline-flex;
      background-color: var(--ctf-sixth);
      justify-content: center;
      float: left;
      border-bottom: 3px solid transparent;
      color: var(--secondary);
      text-transform: capitalize;

      &.active {
        border-bottom: 3px solid var(--primary);
      }

      &.completed {
        background-color: var(--primary);
        color: var(--ctf-white);
      }

      .step-inner-block {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        .block-1 {
          background-color: var(--ctf-white);
          border-radius: 50%;
          padding: 5px;
          margin-right: 10px;
          width: 34px;
          height: 34px;
        }
      }
    }
  }
}

/*memberinfo page css*/

.custom-inputs {
  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      input {
        font-size: var(--ctf-h4);
        padding: 16px;
        height: 56px;
        outline: transparent !important;
        box-shadow: none;
        color: var(--ctf-black);
        font-weight: 500;
        width: 100%;
        border: 1px solid var(--ctf-third);
        background-image: url("../images/date_range.svg");
        background-repeat: no-repeat;
        background-position: center right 15px;
        border-radius: 5px;

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: var(--secondary);
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          color: var(--secondary);
        }

        &:-ms-input-placeholder {
          /* IE 10+ */
          color: var(--secondary);
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          color: var(--secondary);
        }

        &:focus {
          border-color: var(--primary);
          outline: 0;
          box-shadow: none;
          border: 2px solid var(--primary);

          &+label {
            color: var(--primary);
          }
        }
      }
    }
  }
}

.user-id-block {

  .form-control:disabled,
  .form-control[readonly] {
    background-color: transparent !important;
  }

  label {
    left: 0px;
  }
}

.select-box {
  select {
    background-image: linear-gradient(45deg, transparent 50%, #999 60%),
      linear-gradient(135deg, #999 40%, transparent 50%) !important;
    background-image: linear-gradient(45deg,
        transparent 50%,
        var(--secondary) 60%),
      linear-gradient(135deg, var(--secondary) 40%, transparent 50%) !important;
    background-position: calc(100% - 25px) 24px, calc(100% - 20px) 24px, 100% 0 !important;
    background-size: 5px 5px, 5px 5px !important;
    background-repeat: no-repeat !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
  }
}

.subscription-date-panel {
  background-color: var(--ctf-sixth) !important;

  .custom-inputs {
    .form-control {
      background-color: transparent !important;
    }

    label {
      background-color: var(--ctf-sixth) !important;
    }
  }
}

/*successful-pop-up-wrapper*/
.successful-pop-up-wrapper {
  padding: 50px;

  .successful-popup-title {
    padding-bottom: 40px;
  }

  .btn-wrapper {
    padding-top: 30px;
  }
}

.custom-link {
  color: var(--primary);

  &:hover,
  &:focus {
    color: var(--ctf-seventh);
  }
}

//shubham added this

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.css-yk16xz-control {
  border: 0 !important;
}

.css-1pahdxg-control {
  border: 0 !important;
}

.css-2613qy-menu {
  display: none !important;
}

.captureimage {
  text-align: center;

  video {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.with-white-background {
  .date-picker-wrapper {
    .react-datepicker__input-container {
      input {
        background-color: var(--ctf-white) !important;
      }
    }
  }
}

/*wod pop up */
.wod-popup {
  .wod-delete-table-wrapper {
    height: 250px;
    overflow: scroll;
  }
}

.css-yk16xz-control {
  font-size: var(--ctf-h4);
  padding: 16px;
  height: 56px;
  outline: transparent !important;
  box-shadow: none;
  color: var(--ctf-black);
  font-weight: 500;
}

/*calender css started*/
.custom-calendar {
  .rbc-today {
    background: rgba(246, 165, 31, 0.2);
  }

  .rbc-row-segment {
    margin-bottom: 4px;
  }

  .rbc-events-container {
    margin-right: 0 !important;
  }

  .rbc-time-content {
    border-top: none !important;
  }

  .rbc-time-slot {
    border-top: none !important;
  }

  .rbc-time-header {
    background: var(--ctf-sixth);
    height: 66px;
    display: flex;
    align-items: flex-end;
  }

  .rbc-timeslot-group {
    min-height: 76px;
  }

  overflow-x: auto;

  .rbc-time-header-content {
    border-left: none;
    padding: 0;

    .rbc-time-header-cell {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .rbc-time-header-gutter {
    padding: 0;

    // background: var(--ctf-sixth);
    .time-gutter-header {
      // background-color: grey;
      width: 100% !important;
      padding: 0;
      height: 100% !important;
    }
  }

  .event-outer-container {
    .event-first-row {
      div:first-child {
        margin-bottom: 6px;
        padding-left: 5px;
      }

      span {
        color: #ffffff;
        font-weight: 700;
        font-size: 10px;
      }
    }

    .event-second-row {
      div {
        padding-left: 5px;
      }

      span {
        color: white;
        font-weight: 500;
        font-size: 10px;
      }
    }
  }

  .rbc-allday-cell {
    display: none;
  }

  //Week-view
  .rbc-time-gutter {
    background: var(--ctf-sixth);

    .rbc-timeslot-group {
      border: none;

      .rbc-label {
        color: #312f2f;
        font-weight: 400;
        font-size: 10px;
      }
    }
  }

  .rbc-header {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: var(--ctf-sixth);

    .calendar-header-day {
      display: inline-flex;
      justify-content: center;
      align-items: flex-end;
      width: 100%;
      letter-spacing: 3px;
      text-transform: uppercase;

      color: #312f2f;
      font-size: 10px;
      line-height: 15px;
    }
  }

  .rbc-month-header {
    height: 66px;

    background: var(--ctf-sixth);
  }

  .rbc-calendar {
    min-width: 600px !important;
  }

  .rbc-toolbar {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
    height: 72px;

    .rbc-toolbar-label {
      flex-grow: unset;
      text-align: left;
      order: 1;
      font-size: var(--ctf-h5);
      color: #353f47;
      min-width: 250px;
    }

    .rbc-btn-group {
      order: 2;
      display: inline-flex;

      &:first-child button {
        position: relative;
        box-shadow: none;

        &:nth-child(1) {
          order: 2;
          border-radius: 2px;
          font-size: var(--body-font-size);
          font-weight: 600;
          color: var(--primary);
        }

        &:nth-child(2) {
          font-size: 0;
          content: " ";
          padding: 15px;
          position: relative;
          border-radius: 0;
          outline: 0;
          order: 1;
          border: 0;

          &:after {
            content: "\f104";
            visibility: visible;
            background-color: transparent;
            position: absolute;
            display: block;
            padding: 0;
            top: 50%;
            font-size: 24px;
            font-family: "FontAwesome";
            transform: translate(-50%, -50%);
            left: 50%;
            font-size: var(--ctf-h5);
          }

          &:hover,
          &:focus {
            color: var(--primary);
            background-color: transparent;
          }
        }

        &:nth-child(3) {
          order: 3;
          border: 0;
          font-size: 0;
          content: " ";
          padding: 15px;
          position: relative;
          border-radius: 0;
          outline: 0;

          &:after {
            content: "\f105";
            visibility: visible;
            background-color: transparent;
            position: absolute;
            display: block;
            padding: 0;
            top: 50%;
            font-size: 24px;
            font-family: "FontAwesome";
            transform: translate(-50%, -50%);
            left: 50%;
            font-size: var(--ctf-h5);
          }

          &:hover,
          &:focus {
            color: var(--primary);
            background-color: transparent;
          }
        }
      }

      &:last-child button {
        background-color: transparent;
        border: 0;
        box-shadow: none;
        font-weight: 700;
        font-size: var(--body-font-size);
        outline: none;

        &:active,
        &.rbc-active {
          color: var(--primary);
        }
      }
    }
  }

  .rbc-month-row {
    min-height: 141px;
  }

  .rbc-header,
  .rbc-time-header-cell {
    border-color: transparent;
    padding: 15px 0;
    text-align: left;
    color: var(--secondary);
    font-weight: 400;
    font-size: var(--body-font-size);
  }

  .rbc-month-view {
    border-color: transparent;

    .rbc-date-cell {
      text-align: left;
      padding-left: 5px;
      padding-top: 5px;
      margin-bottom: 8px;
    }

    .rbc-off-range-bg {
      background-color: #ffffff;
    }
  }

  .rbc-time-header.rbc-overflowing,
  .rbc-header+.rbc-header,
  .rbc-time-header-gutter,
  .rbc-time-header-content,
  .rbc-time-view {
    // border-color: transparent;
    border: none;
  }

  .rbc-today {
    color: var(--primary);
  }

  // event css started
  .rbc-event {
    border-radius: 0px;
    box-shadow: none;
    transition: box-shadow 500ms;

    &:hover {
      box-shadow: 3px 5px 5px 0 rgba(0, 0, 0, 0.35);
    }
  }

  .rbc-event.rbc-selected {
    background-color: #4d4467;
    outline: transparent;
  }

  .rbc-event {
    background-color: #4d4467;
    position: relative;
    // margin-top: 10px;
  }

  .rbc-event-content {
    min-height: 30px;
    // background-color: rgba(241, 241, 241, 0.05);
    // box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    // color: #312f2f;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 10px;
    outline: transparent;
    padding: 5px 10px;

    .event-blk {
      position: relative;
      padding: 0 50px 0 0;

      .event-blk-content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .action-block {
        display: inline-block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);

        .calendar-action-list {
          .calendar-action-list-element {
            display: inline-block;
          }
        }
      }

      &.reserve-block {
        .event-blk-content {
          max-width: calc(100% - 120px);
        }

        &.reser-btn-blk-width {
          .event-blk-content {
            max-width: 100%;
          }
        }

        .reserveclass-btn {
          font-size: 11px;
          line-height: 13px;
          padding: 5px;
          font-weight: 400;

          svg {
            width: 20px;
            height: 20px;

            path {
              fill: var(--ctf-black);
            }
          }
        }
      }
    }
  }

  .rbc-allday-cell {
    .rbc-day-bg+.rbc-day-bg {
      border-left: 1px solid transparent;
    }
  }
}

.close-icons {
  padding-bottom: 15px;

  .svg-block {
    cursor: pointer;

    &:hover {
      svg {
        path {
          fill: #4d4467;
          @include transition;
        }
      }
    }
  }
}

.booking-table {
  .booking-btn {
    padding: 10px;
    min-width: 110px;
  }
}

/*custom date picker css */
.react-datepicker__day--keyboard-selected {
  background-color: var(--primary) !important;
}

.react-datepicker__month-text--keyboard-selected {
  background-color: var(--primary) !important;
}

.react-datepicker__quarter-text--keyboard-selected {
  background-color: var(--primary) !important;
}

.react-datepicker__year-text--keyboard-selected {
  background-color: var(--primary) !important;
}

react-datepicker__day--selected {
  background-color: var(--primary) !important;
}

.react-datepicker__day--in-selecting-range {
  background-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.react-datepicker__day--in-range {
  background-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.react-datepicker__month-text--selected {
  background-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.react-datepicker__month-text--in-selecting-range {
  background-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.react-datepicker__month-text--in-range {
  background-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.react-datepicker__quarter-text--selected {
  background-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.react-datepicker__quarter-text--in-selecting-range {
  background-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.react-datepicker__quarter-text--in-range {
  background-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.react-datepicker__year-text--selected {
  background-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.react-datepicker__year-text--in-selecting-range {
  background-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.react-datepicker__year-text--in-range {
  background-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.react-datepicker__current-month {
  color: var(--primary) !important;
}

.react-datepicker-time__header {
  color: var(--primary) !important;
}

.react-datepicker-year-header {
  color: var(--primary) !important;
}

.react-datepicker__day--selected {
  background-color: var(--primary) !important;
}

.react-datepicker__header {
  background-color: var(--ctf-sixth) !important;
  border-bottom: 1px solid var(--primary) !important;
}

.react-datepicker {
  font-family: var(--font-family) !important;
  border: 1px solid var(--primary) !important;
}

.react-datepicker-popper[data-placement^="top"] {
  .react-datepicker__triangle {
    &::before {
      border-top-color: var(--primary) !important;
    }
  }
}

.react-datepicker__year-read-view--down-arrow {
  &::before {
    border-top-color: var(--primary) !important;
  }
}

.react-datepicker__month-read-view--down-arrow {
  &::before {
    border-top-color: var(--primary) !important;
  }
}

.react-datepicker__month-year-read-view--down-arrow {
  &::before {
    border-top-color: var(--primary) !important;
  }
}

.react-datepicker-popper[data-placement^="bottom"] {
  .react-datepicker__triangle {
    &::before {
      border-bottom-color: var(--primary) !important;
    }
  }
}

.custom-disable {
  input:disabled {
    background-color: transparent;
    border-color: transparent;
    font-weight: 600;
  }

  .lowSize {
    font-size: 12px;
    font-weight: 600;
  }

  .PhoneInput {
    padding: 13px 0;
  }

  .PhoneInputInput {
    color: var(--ctf-black);
    font-weight: 500;
  }
}

.w-95 {
  width: 95px;
}

.upload-image-modal {
  padding: 50px;

  .up-image-block {
    width: 400px;
    padding: 30px;
    margin: 0 auto;
    border-radius: 5px;
    border: 1px dashed var(--primary);

    .upload-img {
      width: 75px;
    }
  }
}

.live-class-btn {
  background-color: var(--ctf-danger);
  border-color: var(--ctf-danger);

  .live-class-svg-block {
    svg {
      width: 40px;
      height: 25px;
      margin-bottom: 5px;
      margin-right: 10px;
      @include transition;
    }
  }

  &:hover,
  &:focus {
    .live-class-svg-block {
      svg {
        path {
          fill: var(--primary);
        }
      }
    }
  }
}

.trainerview-main-block {
  background-color: var(--ctf-black);
  height: 100vh;
  // height: 900px;
  min-height: 768px;
  align-items: center;
  display: flex;

  .live-custom-container {
    padding: 0 150px;
  }

  .title-content {
    margin-left: 40px;
  }

  .trainer-view-title {
    padding: 30px 0px;
  }

  .user-info-block {
    .block-1 {
      display: inline-flex;
      width: 32px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .block-2 {
      display: inline-flex;
    }
  }

  .video-block {
    width: 100%;
    max-height: 700px;
  }

  video {
    max-height: 700px;
  }

  .comment-main-block {
    .comment-listing-block {
      max-height: 410px;
      overflow-y: auto;

      .list-element {
        margin-bottom: 15px;

        .block-1 {
          display: inline-flex;
          width: 32px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .block-2 {
          display: inline-flex;
          background-color: rgba(255, 255, 255, 0.1);
          padding: 8px 15px;
          border-radius: 15px;
          width: calc(100% - 50px);

          .user-name {
            &:after {
              display: inline-flex;
              width: 5px;
              height: 5px;
              background-color: var(--ctf-danger);
              content: " ";
              align-items: center;
              margin: auto 5px;
              vertical-align: middle;
              align-self: center;
              border-radius: 50%;
            }
          }

          .timer {
            font-weight: 300;
          }

          .comment {
            font-size: 400;
          }
        }
      }
    }
  }

  .live-block {
    .live {
      background-color: var(--ctf-danger);
      padding: 10px 15px;
      border-radius: 15px;
      color: var(--ctf-white);
      font-weight: 700;

      &:before {
        vertical-align: middle;
        width: 5px;
        height: 5px;
        display: inline-flex;
        content: " ";
        align-items: center;
        margin: auto 5px;
        vertical-align: middle;
        border-radius: 50%;
        background-color: var(--ctf-white);
      }
    }

    .eye-icons {
      width: 20px;
    }

    .timer {
      margin-left: 5px;

      img {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.add-member-popup {
  .card-panel {
    padding: 40px;
    border-radius: 5px;
  }

  .ex-space-y {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .ex-space-pt {
    padding-top: 0px;
  }

  .ex-space-py {
    padding-bottom: 0px;
  }
}

//  Add Member Form New

#gender-dd-container {
  margin-top: 40px;
}

.family-membership-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  margin-left: 3px;
  max-width: fit-content;
  cursor: pointer;

  span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-left: 9px;
    /* identical to box height, or 150% */

    color: #4d4467;
  }
}

.relation-delete-btn {
  position: absolute;
  right: 7%;
}

.family-member-info-row {
  background: #f5f7f8;
}

.family-membership-table {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 0;
  white-space: nowrap;

  .family-membership-table-inner-container {
    width: 100%;
    background: #ffffff;
    border: 1px solid #e4e7eb;
    border-radius: 4px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;

    .family-membership-row-container {
      display: grid;
      height: 50px;
      grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
      align-items: center;
      width: 100%;

      p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
      }

      p:first-child {
        margin-left: 15px;
      }
    }

    .family-membership-row-container:nth-child(even) {
      background: #f5f7f8;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .family-membership-row-container {
    grid-template-columns: repeat(3, 1fr);

    p {
      margin-right: 15px;
    }
  }
}

#grey-date-picker {
  background: #f5f7f8;
  background-image: url(/static/media/date_range.640999d6.svg);
  background-repeat: no-repeat;
  background-position: center right 15px;
}

.outlined-button-custom {
  background: #f5f7f8;
  border: 1px solid #4d4467;
  border-radius: 5px;
  color: #4d4467;
  padding: 14px 50px;
  transition: 1100ms;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  text-transform: uppercase;

  &:hover {
    border: 1px solid #f5f7f8;
    color: #f5f7f8;
    background: #4d4467;
  }
}

.video-react {
  .video-react-video {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
  }
}

.video-react.video-react-fluid {
  height: 100%;
  max-height: 100%;
}

.video-react.video-react-16-9 {
  height: 100%;
  max-height: 100%;
}

.video-react.video-react-4-3 {
  height: 100%;
  max-height: 100%;
}

.video-react-controls-enabled.video-react-has-started.video-react-paused.video-react-fluid.video-react-user-inactive.video-react-workinghover.video-react {
  padding-top: 500px !important;
}

.owl-carousel {
  .owl-nav {
    button.owl-prev {
      font-size: 24px !important;
      line-height: 40px !important;
      padding: 0px 10px !important;
      outline-color: var(--primary);
    }

    button.owl-next {
      font-size: 24px !important;
      line-height: 40px !important;
      padding: 0px 10px !important;
      outline-color: var(--primary);
    }

    button {
      span {
        padding: 5px 10px;
      }
    }
  }

  button.owl-dot {
    font-size: 24px !important;
    line-height: 40px !important;
    padding: 0px 10px !important;
    outline-color: var(--primary);
  }
}

.owl-theme {
  .owl-nav {
    [class*="owl-"] {
      &:hover {
        background: var(--primary) !important;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

.rbc-overlay {
  min-width: 350px !important;
  max-height: 450px !important;
  overflow: scroll;

  .rbc-event.rbc-event-allday {
    position: relative;
    background-color: transparent;
    outline: transparent;

    .rbc-toolbar {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      font-size: 14px;

      .rbc-toolbar-label {
        flex-grow: unset;
        text-align: left;
        order: 1;
        font-size: var(--ctf-h5);
        color: #353f47;
        min-width: 250px;
      }

      .rbc-btn-group {
        order: 2;
        display: inline-flex;

        &:first-child button {
          position: relative;
          box-shadow: none;

          &:nth-child(1) {
            order: 2;
            border-radius: 2px;
            font-size: var(--body-font-size);
            font-weight: 600;
            color: var(--primary);
          }

          &:nth-child(2) {
            font-size: 0;
            content: " ";
            padding: 15px;
            position: relative;
            border-radius: 0;
            outline: 0;
            order: 1;
            border: 0;

            &:after {
              content: "\f104";
              visibility: visible;
              background-color: transparent;
              position: absolute;
              display: block;
              padding: 0;
              top: 50%;
              font-size: 24px;
              font-family: "FontAwesome";
              transform: translate(-50%, -50%);
              left: 50%;
              font-size: var(--ctf-h5);
            }

            &:hover,
            &:focus {
              color: var(--primary);
              background-color: transparent;
            }
          }

          &:nth-child(3) {
            order: 3;
            border: 0;
            font-size: 0;
            content: " ";
            padding: 15px;
            position: relative;
            border-radius: 0;
            outline: 0;

            &:after {
              content: "\f105";
              visibility: visible;
              background-color: transparent;
              position: absolute;
              display: block;
              padding: 0;
              top: 50%;
              font-size: 24px;
              font-family: "FontAwesome";
              transform: translate(-50%, -50%);
              left: 50%;
              font-size: var(--ctf-h5);
            }

            &:hover,
            &:focus {
              color: var(--primary);
              background-color: transparent;
            }
          }
        }

        &:last-child button {
          background-color: transparent;
          border: 0;
          box-shadow: none;
          font-weight: 700;
          font-size: var(--body-font-size);
          outline: none;

          &:active,
          &.rbc-active {
            color: var(--primary);
          }
        }
      }
    }

    .rbc-header,
    .rbc-time-header-cell {
      border-color: transparent;
      padding: 15px 0;
      text-align: left;
      color: var(--secondary);
      font-weight: 400;
      font-size: var(--body-font-size);
    }

    .rbc-month-view {
      border-color: transparent;

      .rbc-date-cell {
        text-align: left;
        padding-left: 5px;
        padding-top: 5px;
      }

      .rbc-off-range-bg {
        background-color: var(--ctf-sixth);
      }
    }

    .rbc-time-header.rbc-overflowing,
    .rbc-header+.rbc-header,
    .rbc-time-header-gutter,
    .rbc-time-header-content,
    .rbc-time-view {
      border-color: transparent;
    }

    .rbc-today {
      color: var(--primary);
    }

    // event css started
    // .rbc-event.rbc-selected {
    //   background-color: transparent;
    //   outline: transparent;
    // }

    .rbc-event {
      background-color: #4d4467;
      position: relative;
      margin-top: 10px;
    }

    .rbc-event-content {
      min-height: 30px;
      background-color: rgba(241, 241, 241, 0.05);
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
      // color: #312f2f;
      font-size: 10px;
      letter-spacing: 0;
      line-height: 10px;
      outline: transparent;
      padding: 5px 10px;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 4px;
        height: 100%;
        content: " ";
        background-color: var(--ctf-seventh);
      }

      .event-blk {
        position: relative;
        padding: 0 50px 0 0;

        .event-blk-content {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .action-block {
          display: inline-block;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);

          .calendar-action-list {
            .calendar-action-list-element {
              display: inline-block;
            }
          }
        }

        &.reserve-block {
          .event-blk-content {
            max-width: calc(100% - 120px);
          }

          .reserveclass-btn {
            font-size: 11px;
            line-height: 13px;
            padding: 5px;
            font-weight: 400;
          }
        }
      }
    }

    .rbc-allday-cell {
      .rbc-day-bg+.rbc-day-bg {
        border-left: 1px solid transparent;
      }
    }
  }
}

.redux-toastr .toastr.rrt-success {
  background-color: #417505 !important;
}

.redux-toastr .toastr.rrt-error {
  background-color: #e72d28 !important;
}

.days-inner-block {
  .subscription-plan-list-element {
    margin-bottom: 10px;
    color: var(--secondary);
    font-weight: normal;
    position: relative;
    margin-bottom: 10px;
  }

  .selction-box {
    .container-blk {
      font-size: var(--ctf-h4);
      min-width: fit-content;
    }
  }
}

.notification-portion {
  .info-title {
    display: flex;
  }

  .title-notification {
    width: calc(100% - 50px);

    display: inline-flex;
  }

  .icons-block {
    width: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;

    .notification-counter {
      background: var(--primary);
      color: var(--ctf-white);
      padding: 5px;
      font-size: 10px;
      border-radius: 50%;
      line-height: 10px;
      display: flex;
      align-items: center;
    }
  }
}

.notification-wrapper {
  .notification-list-block {
    .notification-list-element {
      padding: 10px 15px;
      display: flex;
      align-items: center;

      border-bottom: 1px solid #e4e7eb;
      background-color: var(--ctf-white);

      &:first-child {
        border-bottom: 1px solid transparent;
      }

      // &:last-child {
      //   border-bottom: 1px solid transparent;
      // }

      &:nth-child(odd) {
        background-color: var(--ctf-sixth);
      }

      .notification-block-1 {
        width: calc(100% - 150px);
        display: inline-flex;
        align-items: center;

        .block-1 {
          width: 35px;
          height: 35px;
          display: inline-flex;
          border-radius: 50%;
          margin-right: 10px;
          position: relative;

          img {
            border-radius: 50%;
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }

        .block-2 {
          width: calc(100% - 60px);
          height: 100%;
          display: inline-flex;
          align-self: center;
        }
      }

      .primary-link-with-border {
        color: var(--primary);
        text-decoration: underline;
      }

      .fifth-link-without-border {
        color: var(--ctf-fifth);
      }

      .notification-block-2 {
        width: 150px;
        display: inline-flex;
        justify-content: flex-end;
      }
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.rbc-overlay {
  -webkit-animation: fadein 0.5s ease-out alternate;
  -moz-animation: fadein 0.5s ease-out alternate;
  animation: fadein 0.5s ease-out alternate;
  border-radius: 5px;

  .rbc-overlay-header {
    font-size: 14px;
    padding: 10px;
  }

  .rbc-event {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
    border-radius: 0px;
    background: #4d4467 !important;

    .event-title {
      font-size: 14px;
      font-weight: 600;
    }

    .event-trainer {
      font-size: 12px;
      font-weight: 400;
    }
  }

  // .rbc-selected {
  //   background-color: #ffffff !important;
  // }

  .rbc-event-content {
    min-height: 30px;
    max-height: 40px;
    background-color: #4d4467;
    // box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    // color: #312f2f;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 10px;
    outline: transparent;
    padding: 5px 10px;
    margin: 5px 0px;

    .event-blk {
      position: relative;
      padding: 0 50px 0 0;

      .action-block {
        display: inline-block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);

        .calendar-action-list {
          .calendar-action-list-element {
            display: inline-block;
          }
        }
      }
    }
  }
}

.rbc-show-more {
  display: flex !important;
  justify-content: center;
  background: rgba(123, 0, 163, 0.15) !important;
  min-height: 23px;
  align-items: center;
}

.rbc-day-slot {
  .rbc-event {
    // color: var(--ctf-seventh);
    // background: #ffffff;
    // border: 1px solid #ffffff !important;
    // box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    // min-height: 40px !important;

    // &.rbc-selected {
    //   background: #ffffff;
    // }

    .rbc-event {
      min-height: 32px !important;
    }
  }

  .rbc-event-content {
    min-height: 25px !important;
  }

  .rbc-event-label {
    flex: none;
    padding-right: 5px;
    width: 100%;
    display: none;
  }
}

/**check-in page*/
.check-in-panel {
  .table-main-block {
    .table.table-striped {
      tbody {
        tr {
          td {
            .edit-action {
              white-space: pre;
            }
          }
        }
      }
    }
  }
}

/**/
.sucess {
  .payment-card {
    padding: 50px;
    border: 1px solid #eeeeee;
    margin: 50px 0;
    border-radius: 25px;
  }
}

.trainerview-new-main-block {
  height: 100% !important;
  display: block;
  min-height: 100vh;

  .grid-view {
    .live-list {
      max-height: 150px;
      overflow-y: auto;

      .live-element {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }

  .video-block {
    width: 100%;
    max-height: 100%;
  }

  .live-block {
    padding: 15px 0;
  }
}

.custom-block-with-transparent-bg {
  display: flex;

  .subscription-plan-list-element {
    .custom-btn-radio {
      background-color: transparent;
      margin-bottom: 15px;
    }
  }
}

.capsual-min-delete {
  top: -8px !important;
  right: 6px !important;

  svg {
    width: 8px;
  }
}

.summary-block {
  border-radius: 5px;
  border-color: #fff;
  z-index: 10;

  .summary-card {
    background-color: #fff;

    h5 {
      font-weight: 700;
    }

    div {
      color: #4d4467;
      font-size: 2.5rem;
      font-weight: 500;
      margin-top: 3rem;
    }
  }
}

.checkin-table-btn {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  color: #8700a9;
}

.checked-in-span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #417505;
}

@media only screen and (max-device-width: $screen-phone) {
  #totals-summary-card {
    display: flex;
    flex-direction: column;

    span {
      margin-top: 30px;

      div {
        margin-top: 10px;
      }
    }
  }
}

.caption-select {
  .css-26l3qy-menu {
    left: 0px !important;
  }
}

.react-datepicker-popper {
  z-index: 99 !important;
}

.trainer-gender-default-wrapper {
  .css-26l3qy-menu {
    left: 0px !important;
  }
}

.apexcharts-toolbar {
  z-index: 8 !important;
}

.apexcharts-menu.apexcharts-menu-open {
  text-align: left !important;
}

// Add Sale
// Add Sale
#no-border {
  border: none;
}

#sale-upper-outer-container {
  padding-bottom: 24px;

  #package-selector {
    padding-left: 15px !important;
  }
}

.packages-container-sale-form {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 300px;
  background: #ffffff;
  border: 1px solid #e4e7eb;
  border-radius: 4px;
  width: calc(100% - 27px);
  margin-top: 24px;
  overflow-y: auto;

  #selected-package-row {
    min-height: 58px;
    align-items: center;

    // overflow-y: auto;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;

      span {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #f6a51f;
        mix-blend-mode: normal;
      }
    }

    input::placeholder {
      font-size: 13px;
    }

    path {
      fill: rgb(0, 0, 0, 0.4);
    }

    #select-specific-member {
      padding-left: 10px !important;
      border-radius: 4px;
      height: 38px !important;
      background-position: calc(100% - 25px) 17px, calc(100% - 20px) 17px,
        100% 0 !important;
    }

    #select-specific-one {
      padding-left: 10px !important;
      border-radius: 4px;
      height: 38px !important;

    }

    #assigned-trainer {
      border-radius: 4px;
      font-size: 15px !important;
      height: 38px !important;
      padding-left: 10px !important;
      background-position: calc(100% - 25px) 17px, calc(100% - 20px) 17px,
        100% 0 !important;
    }

    .selected-package-start-date-container {
      height: 38px;

      .react-datepicker__input-container input {
        height: 38px !important;
        font-size: 14px;
      }
    }

    .discount-field-col::after {
      content: "%";
      position: absolute;
      top: 18px;
      /* Borders */
    }

    #discount-percent-field {
      height: 38px !important;
    }
  }
}

@media screen and (min-width: 1201px) {
  .packages-container-sale-form #selected-package-row {
    #discount-percent-field {
      padding-left: 15px !important;
      margin-left: -39% !important;
    }

    #assigned-trainer {
      margin-left: -44% !important;
      margin-right: 2%;
    }

    .discount-field-col::after {
      right: 70%;
    }
  }

  #delete-icon-sale {
    margin-left: -88px !important;
  }

  #discount-header {
    margin-left: -5% !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .col-lg-2 {
    flex: 0 0 50%;
    max-width: 51%;
  }

  .packages-container-sale-form {
    #selected-package-row {
      #discount-percent-field {
        padding-left: 10px !important;
      }

      .discount-field-col::after {
        right: 20%;
      }
    }

    .delete-button-container {
      position: relative;
      bottom: 48% !important;
      right: -91%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .packages-container-sale-form {
    min-height: 256px !important;
    height: auto;

    .selected-class-name {
      padding-top: 14px;
      padding-bottom: 17px;
    }

    .delete-button-container {
      position: relative;
      bottom: 90%;
      left: 83%;
    }

    #selected-package-row #select-specific-member {
      width: 100% !important;
    }

    #selected-package-row {
      .discount-field-col::after {
        right: 20%;
      }

      #discount-percent-field {
        padding-left: 10px !important;
      }
    }
  }

  .sale-summary {
    height: auto !important;

    .summary-item-sigle-container {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #selected-package-row {
    justify-content: flex-start !important;
  }

  .packages-container-sale-form {
    min-height: 500px !important;
    height: auto;

    .selected-class-name {
      padding-top: 14px;
      padding-bottom: 17px;
    }

    .delete-button-container {
      position: relative;
      bottom: 48% !important;
      right: -91%;

    }

    #discount-percent-field {
      padding-left: 15px !important;
      // margin-left: 0!important;
    }

    .discount-field-col::after {
      right: 61%;
    }
  }

  .sale-summary {
    height: auto !important;

    .summary-item-sigle-container {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.custom-add-sale-input {
  .css-1pvho20-control {
    height: 100% !important;
  }

  .css-wgkyk0-control {
    height: 100% !important;
  }
}

.sale-summary {
  background: #f5f7f8;
  border-radius: 4px;
  align-items: center;
  height: 53px;
  width: 100%;
  margin-top: 16px;

  .col-6 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  span {
    font-size: 14px;
  }

  .item-text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    margin-right: 8px;
    /* identical to box height, or 150% */

    color: #000000;

    mix-blend-mode: normal;
  }
}


//////////// ADD TRAINER SCHEDULE DAYS
///

.schedule-checkbox-list-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e4e7eb;

  .row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .schedule-row {
    display: flex;
    align-items: center;
    margin-right: 0 !important;
    width: 100%;
    min-height: 53px;
    border: 1px solid #e4e7eb;

    .schedule-checkbox-element {
      display: flex;
      justify-content: start;
      align-items: center;
      height: 100%;

      .selected-times-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 15px;
        column-gap: 10px;
        margin: 10px 0px;
      }

      .schedule-checkboxes-list {
        display: flex;
        justify-content: start;
        align-items: center;
        padding-left: 18px !important;
      }

      .time-card-container {
        background: #f6a51f;
        border-radius: 4px;
        width: 150px;

        .time-card-inner-container {
          display: flex;
          padding: 8px;
          justify-content: space-around;
          color: #ffffff;

          .time-card-texts {
            font-size: 13px;
            line-height: 21px;
            margin-right: 1%;
          }

          .times-card-delete {
            cursor: pointer;
          }
        }
      }

      .container-blk {
        margin-bottom: 0 !important;
        margin-right: 50px;
      }
    }
  }

  .select-box {
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #ffffff;
    height: 37px;
    max-width: fit-content;

    select:first-child {
      border-right-style: solid;
      border-radius: 0px;
    }

    border: 1px solid #dddddd;
    border-radius: 4px;
    cursor: pointer;
  }

  .times-dropdown {
    opacity: 0.5;
    background-image: linear-gradient(45deg, transparent 50%, #999 60%),
      linear-gradient(135deg, #999 40%, transparent 50%) !important;
    background-image: linear-gradient(45deg,
        transparent 50%,
        var(--secondary) 60%),
      linear-gradient(135deg, var(--secondary) 40%, transparent 50%) !important;
    background-repeat: no-repeat !important;
    background-size: 5px 5px, 5px 5px !important;
    cursor: pointer;
    width: 75px;
    height: 80%;
    border-style: none;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .times-dropdown:focus {
    opacity: 1;
  }

  .times-dropdown:first-child {
    background-position: calc(100% - 8px) 12px, calc(100% - 3px) 12px, 100% 0 !important;
  }

  .times-dropdown:last-child {
    background-position: calc(100% - 8px) 12px, calc(100% - 3px) 12px, 100% 0 !important;
  }

  .times-dropdown:focus {
    border-style: none;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0px;
    opacity: 0;
    position: relative;
    padding-left: 12%;
    cursor: pointer;
  }

  input[type="time"]::-moz-calendar-picker-indicator {
    position: absolute;
    left: 0px;
    opacity: 0;
    position: relative;
    padding-left: 12%;
    cursor: pointer;
  }

  .time-from,
  .time-to {
    font-size: 11px;
    padding-right: 0;
  }

  @media only screen and (max-width: 767px) {
    .schedule-row {
      height: auto;
      align-items: flex-start;
    }

    .schedule-checkbox-element {
      align-items: flex-start !important;
      flex-direction: column;
    }

    .times-dropdown {
      height: 80% !important;
      width: 100px;
    }
  }

  @media only screen and (max-width: 905px) and (min-width: 601px) {
    .select-box {
      margin-bottom: 0px !important;
    }

    .selected-times-container {
      grid-template-columns: repeat(2, 0.1fr) !important;
      margin: 10px 0 10px 18px;
    }

    .times-dropdown {
      height: 80% !important;
      width: 75px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .selected-times-container {
    grid-template-columns: repeat(1, 0.1fr) !important;
    margin-left: 18px;
    margin-top: 10px;
  }

  .times-dropdown {
    height: 80% !important;
    width: 75px !important;
  }
}

@media only screen and (orientation: landscape) {
  .container-blk {
    min-width: 140px;
  }
}

@media only screen and (orientation: portrait) {
  .container-blk {
    max-width: 90px;
  }
}

// Add Class New

.class-price {
  small {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    color: #000000;

    mix-blend-mode: normal;
    opacity: 0.5;
  }
}

#bg-grey {
  background: #f5f7f8;
}

.waiting-list-shift-left {
  position: relative;
  right: 10px;
}

.black-radio-fonts {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000000 !important;
}

.waitListCapacity {
  label {
    left: 10px !important;
  }
}

@media screen and (max-width: 768px) {
  .waiting-list-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;

    small {
      margin: 0 !important;
    }
  }
}

@media screen and (max-width: 576px) {
  .add-class-date-wrapper {
    align-items: flex-start !important;

    .start-date,
    .end-date {
      width: 100%;

      .react-datepicker-wrapper {
        width: 100%;
      }

      .react-datepicker__input-container {
        width: 100%;

        input {
          max-width: 100% !important;
          min-width: 100% !important;
        }
      }
    }
  }
}

.member-info-bold {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.member-info-titles {
  font-weight: 400;
  font-size: 12px;
  color: grey;
}

//New Appointments

.appointment-info-container {
  background-color: var(--ctf-sixth);

  .appointment-info-bold {
    font-weight: 600;
    font-size: 14px;
    color: #000000;
  }

  .appointment-info-titles {
    font-weight: 400;
    font-size: 12px;
  }
}

.m-0-auto {
  margin: 0 auto;
}

.cancelation-field-radios-wrapper {
  label {
    color: black !important;
  }
}

.event-edit-button {
  width: 25%;
  transition: 1s;

  &:hover {
    width: 35%;
  }
}

.new-appointment-date {
  height: 42px !important;
}

.tool-bar-container {
  // display: flex;
  align-items: center;

  div:has(.toolbar-label) {
    padding-right: 0px;
  }

  .toolbar-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #353f47;
    // margin: 0 40px 0 24px;
  }

  .toolbar-today {
    background: #ffffff;
    border: 1px solid #d0d5d9;
    border-radius: 3px;
    color: #4d4467;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    padding: 8px 16px;
    // margin: 0 203px 0 40px;
  }

  .toolbar-selector {
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 5px;
    width: 200px;
    padding: 13px 16px 13px 18px;
    background-image: linear-gradient(45deg,
        transparent 50%,
        var(--secondary) 60%),
      linear-gradient(135deg, var(--secondary) 40%, transparent 50%) !important;
    background-position: calc(100% - 25px) 21px, calc(100% - 20px) 21px, 100% 0;
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: transparent;
    box-shadow: none;
  }

  .toolbar-view-name {
    color: #637280;

    mix-blend-mode: normal;
    opacity: 0.75;
    font-weight: 400;
    font-size: 14px;

    &:nth-child(2) {
      margin-left: 16px;
    }

    cursor: pointer;

    &.selected {
      color: #4d4467;
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 576px) {
  .custom-calendar {
    max-width: 100vw;
    //   position: relative;
    //   top: 110px;
  }

  .tool-bar-container {
    padding: 60px 40px;
    position: relative;

    div:has(.toolbar-label) {
      position: absolute;
      top: 10px;
      left: 10px;
      // margin: 10px 10px;
    }

    div:has(.toolbar-selector) {
      position: absolute;
      top: 55px;
      left: 22%;
      margin: 10px 0px;

      .toolbar-selector {
        width: 65%;
      }
    }

    div:has(.toolbar-today) {
      position: absolute;
      top: 60px;
      left: 10px;
      margin: 10px 0px;
    }

    div:has(.toolbar-view-name) {
      position: absolute;
      top: 5px;
      left: 22%;
      margin: 10px 0px;
    }

    .navigation-container {
      button {
        position: relative;
        right: 43px;
      }

      img {
        &:nth-child(2) {
          position: relative;
          left: 70px;
        }
      }
    }

    .classes-selector {
      position: relative !important;
      left: 0px !important;
      bottom: 0px !important;
      padding: 15px !important;
      width: 100% !important;
    }
  }
}

@media screen and (576px <=width <=900px) {
  .custom-calendar {
    max-width: 100vw;
  }

  .tool-bar-container {
    div {
      margin-bottom: 15px !important;
    }

    .navigation-container {
      button {
        position: relative;
        right: 43px;
      }

      img {
        &:nth-child(2) {
          position: relative;
          left: 70px;
        }
      }
    }

    .toolbar-selector {
      width: 90%;
    }

    div:has(.toolbar-today) {
      max-width: 163px !important;
      margin-right: 2vw;
    }

    div:has(.toolbar-view-name) {
      position: relative;
      top: 6px;
      min-width: 30%;
      display: inline-flex;
      justify-content: space-around;

      .toolbar-view-name {
        margin-bottom: 11px;
      }

      .toolbar-view-name:last-child {
        margin-left: 7px !important;
      }
    }

    // .toolbar-selector {
    //   position: absolute;
    //   left: 150px;
    //   bottom: 25px;
    //   padding: 10px 16px 10px 18px;
    //   width: 150px;
    // }
    // .classes-selector {
    //   position: relative !important;
    //   left: 0px !important;
    //   bottom: 0px !important;
    //   padding: 15px !important;
    //   width: 100% !important;
    // }
  }
}

// Classes Page

// @keyframes expandline {
//   0% {
//     height: 69px;
//   }
//   50% {
//     height: 150px;
//   }
//   100% {
//     height: 150px;
//   }
// }

.classes-page-container {
  padding: 32px 60px 0px 60px;

  .classes-toolbar {
    border-radius: 4px;
  }

  .collapsible-table-container {
    border-radius: 4px;
    -webkit-animation: fadein 0.7s ease-out alternate;
    -moz-animation: fadein 0.7s ease-out alternate;
    animation: fadein 0.7s ease-out alternate;

    .single-day-row {
      margin-left: 0;
      margin-right: 0;
      padding: 24px;
      max-height: 69px;
      border: 1px solid #e0e7f0;

      &:first-child {
        border-radius: 10px 10px 0 0;
      }

      &:last-child {
        border-radius: 0 0 10px 10px;
      }

      .single-day-day-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #000000;
        margin-right: 4px;
      }

      .single-day-count-text {
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;
        color: #000000;
        opacity: 0.7;
      }

      .row-toggler {
        cursor: pointer;
      }

      &.expanded-row {
        max-height: fit-content !important;

        .class-edit-button {
          width: 15px;

          &:hover {
            width: 20px;
          }
        }

        .no-classes-day {
          width: 100%;
          justify-content: center;
          border-radius: 6px;

          .no-classes-texts {
            font-weight: 400;
            font-size: 12px;
          }
        }
      }

      &:nth-child(odd) {
        background: #ffffff;
        transition: 1s;

        .class-outer-container {
          background-color: #f5f7f8;
        }

        .no-classes-day {
          background-color: #f5f7f8;
        }
      }

      &:nth-child(even) {
        background: #f5f7f8;
        transition: 1s;

        .class-outer-container {
          background-color: white;
        }

        .no-classes-day {
          background-color: white;
        }
      }

      .classes-row-container {
        margin-top: 24px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        row-gap: 15px;
        column-gap: 25px;

        .class-outer-container {
          display: flex;
          flex-direction: column;
          width: 143px;
          height: 79px;
          border-radius: 6px;
          transition: box-shadow 1s;

          &:hover {
            box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.4);
          }

          .class-inner-container {
            padding: 4px;
            cursor: pointer;

            .class-first-row {
              div:first-child {
                margin-bottom: 6px;
              }

              img {
                position: relative;
                left: -5px;
              }

              span {
                color: black;
                font-weight: 700;
                font-size: 10px;
              }
            }

            .class-second-row {
              span {
                color: black;
                font-weight: 500;
                font-size: 10px;
              }
            }
          }

          .plus-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1336px) {
  .classes-row-container {
    grid-template-columns: repeat(5, 1fr) !important;
    row-gap: 15px;
  }
}

@media screen and (max-width: 900px) {
  .classes-row-container {
    grid-template-columns: repeat(4, 1fr) !important;
    row-gap: 15px;
  }
}

@media screen and (max-width: 768px) {
  .classes-row-container {
    grid-template-columns: repeat(3, 1fr) !important;
    row-gap: 15px;
  }
}

@media screen and (max-width: 601px) {
  .classes-row-container {
    grid-template-columns: repeat(2, 1fr) !important;
    row-gap: 15px;
  }
}

@media screen and (max-width: 465px) {
  .classes-row-container {
    grid-template-columns: repeat(1, 1fr) !important;
    justify-items: center;
    row-gap: 15px;
    padding-right: 0px;
    padding-left: 0px;

    .class-outer-container {
      width: 100% !important;
    }
  }
}

// End Add Class New

table tr:last-child td:first-child {
  border: 2px;
  border-bottom-left-radius: 4px;
}

table tr:last-child td:last-child {
  border: 2px;
  border-bottom-right-radius: 4px;
}

.purplish-link {
  font-weight: 700;
  font-size: 14px;
  text-decoration-line: underline;
  color: var(--primary);
  cursor: pointer;
}

.red-link {
  font-weight: 500;
  font-size: 14px;
  text-decoration-line: underline;
  color: var(--ctf-danger);
  cursor: pointer;
}

.purple-text {
  color: var(--primary);
}

.purple-border {
  border: 1px solid var(--primary);
}

.toolbar-selector {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  width: 100%;
  padding: 13px 16px 13px 18px;
  background-image: linear-gradient(45deg,
      transparent 50%,
      var(--secondary) 60%),
    linear-gradient(135deg, var(--secondary) 40%, transparent 50%) !important;
  background-position: calc(100% - 25px) 21px, calc(100% - 20px) 21px, 100% 0;
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: transparent;
  box-shadow: none;
}

.hoverable-purple-btn {
  border: 1px solid transparent;

  &:hover {
    border: 1px solid var(--primary) !important;
  }
}

.green-color-font {
  color: #417505;
}

@media screen and (max-width: 767px) {
  .toolbar-selector-container {
    margin-top: 40px;
  }
}

.outlined-hoverable {
  transition: 400ms;

  &:hover {
    color: white;
    background-color: var(--primary) !important;
  }
}

.font-size-14 {
  font-size: 14px;
}

.font-size-12 {
  font-size: 12px;
}

.ctf-p-btn {
  background: #4d4467;
  border-radius: 5px;
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  transition: 500ms;

  &:hover {
    background-color: white;
    border: 2px solid #4d4467;
    color: #4d4467;
  }
}

.classes-table-res {
  overflow-x: scroll;
}

.circle-div-with-bg {
  border-radius: 50%;
  cursor: pointer;
}

.transition-on-show {
  transition: 1000ms ease-in;
}

.static-first-column {
  thead {
    tr {
      th:first-child {
        position: sticky;
        background: inherit;
        left: 0px;
      }
    }
  }

  tbody {
    tr {
      td:first-child {
        position: sticky;
        background: inherit;
        left: 0px;
      }
    }
  }
}

// Main Dashboard Newwwwww

.weekly-schedule-subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  color: #64748b;
}

.weekly-schedule-titles {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #0f172a;
}

.select-filter-wrapper {
  position: relative;

  &:nth-child(2) {}

  select {
    padding-left: 14px;
    background-image: linear-gradient(45deg,
        transparent 50%,
        var(--secondary) 60%),
      linear-gradient(135deg, var(--secondary) 40%, transparent 50%) !important;
    background-image: linear-gradient(45deg,
        transparent 50%,
        var(--secondary) 60%),
      linear-gradient(135deg, var(--secondary) 40%, transparent 50%) !important;
    background-position: calc(100% - 25px) 21px, calc(100% - 20px) 21px, 100% 0;
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: transparent;
    box-shadow: none;
    height: 47px;
    border-radius: 10px;
    width: 100%;
    cursor: pointer;

    &:focus {
      border-color: var(--primary);
    }
  }
}

.color-guide-outer-container {
  display: inline-flex;
  justify-content: flex-end;

  .color-guide-container {
    background-color: var(--ctf-sixth);
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 115px;
    height: 78px;
    border-radius: 5px;

    .color-guide-texts {
      font-size: 12px;
      line-height: 18px;

      /* identical to box height, or 150% */
      text-align: center;

      color: #000000;
    }

    .color-guide-color {
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }
  }
}

@media screen and (max-width: 767px) {
  .color-guide-outer-container {
    margin-top: 10px;
  }

  .weekly-row-title {
    width: fit-content;
    min-width: 125px !important;
  }

  .weekly-schedule-toolbar {
    div:not(:first-child) {
      margin-top: 16px;
    }
  }
}

.weekly-row {
  min-height: 96px;

  .weekly-row-title {
    min-width: 160px !important;
  }

  .weekly-cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 16px;
    max-width: fit-content;
    overflow-x: scroll;
    padding-bottom: 12px;
  }

  display: grid;
  border-radius: 10px;

  &:nth-child(odd) {
    background: #f8fafc;
  }

  .weekly-class-card-container {
    background-color: white;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    width: 120px;

    .weekly-class-card-info {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 992px) {
  .classes-actions {
    div {
      button {
        font-size: 10px;
        padding: 1px;
        width: 100%;
      }
    }

    .classes-cancel {
      position: relative;
      top: 7px;
      left: 35%;
    }
  }
}

@media screen and (max-width: 576px) {
  .classes-cancel {
    position: relative;
    top: 7px;
    left: 25%;
  }

  .classes-actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.color-indicator {
  width: 6px;
  height: 64px;
  border-radius: 3px 0px 0px 4px;
}

.count-card-container {
  .count-card-inner {
    padding: 16px;
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    display: flex;
  }

  .count-card-info {
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .count-card-type {
      font-weight: 500;
      font-size: 12px;
      line-height: 21px;
      color: #64748b;
      mix-blend-mode: normal;
    }
  }
}

.class-bg {
  background: #19871d;
}

.appointment-bg {
  background: #f6a51f;
}

.dots-over-flow {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 15px;
}

.counts-image-circle {
  min-width: 46px;
  min-height: 46px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  img {
    width: 16px;
  }
}

.checkin-select-filter {
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding-inline-end: 130px;

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }
}

.red-circle-bg {
  background: rgba(236, 76, 71, 0.2);
}

.yellow-circle-bg {
  background: rgba(246, 165, 31, 0.2);
}

.purple-circle-bg {
  background: rgba(135, 0, 169, 0.2);
}

.blue-circle-bg {
  background: rgba(74, 144, 226, 0.2);
}

#side-DD {
  position: relative !important;
  transform: none !important;
  top: -30px !important;
  height: fit-content;
  border: none;
  width: 100%;

  .active {
    .link-text {
      color: var(--primary);
    }

    width: 105%;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  // font-size: 12px;
  // padding: 0;
  // margin: 0;
  // border: none !important;
  // cursor: pointer;
  // background:  #F8FAFC;
  // left: 8px !important;
}

// .dropdown{
//   .link-img{
//     padding-bottom: 12px;
//   }
// }

.list-element {
  .link-blk-dd-toggle {
    text-decoration: none;
    color: var(--secondary);
    margin: 0 0 0 0;
    padding: 15px 0px;
    padding-right: 0px;
    padding-left: 0px;
    display: block;
    position: relative;
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;

    .link-data {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;

      .link-img {
        display: inline-flex;
        vertical-align: top;
        width: 35px;
        padding-left: 0;
        transition: all 0.2s;
      }

      .link-text {
        display: inline-flex;
        width: calc(100% - 50px);
      }
    }
  }

  .dropdown-border-bottom {
    .link-text {
      width: 100% !important;
    }
  }

  .show {

    // background-color: var(--ctf-sixth);
    .border-btm-dd {
      // border-bottom: 1px solid #e2e8f0;
      padding-bottom: 12px;
    }

    .dropdown-border-bottom {
      .link-text {
        width: 100% !important;
      }
    }

    .link-blk-dropdown {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: var(--secondary);
      cursor: pointer;
      padding-bottom: 16px;

      &:hover {
        color: var(--primary);
      }

      .link-data {
        position: relative;
        left: 45px;
      }
    }
  }
}

// Page Card (Reports and Services)

.page-card-component {

  a,
  .link-alter {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #f8fafc;
    border-radius: 10px;
    height: 96px;
    min-width: 280px;
    font-size: 14px;
    font-weight: 600;
    color: black;
    width: 100%;
    text-decoration: none;
    transition: background 500ms;

    &:hover {
      background: rgba(235, 166, 7, 0.1);
    }

    .page-card-outer-container {
      width: 100%;
      text-decoration: none;

      .page-card-inner-container {
        padding: 24px 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        .page-card-img-container {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        .page-card-arrow-container {
          display: inline-block;
        }
      }
    }
  }
}

.w-200 {
  width: 200px;
}

.h-150 {
  height: 120px;
}

.active-day {
  background-color: orange;
  color: white;
  font-weight: 700;
  border-radius: 15px;
  padding: 3px 7px;
}

.btn-xs {
  padding: 5px 12px;
}

.min-h-445 {
  height: 445px;
}

.non-active-day {
  color: rgba(128, 128, 128, 0.552);
}

.disabled-card {
  pointer-events: none;
  opacity: 0.6;
}

.new-info-title {
  height: 60px;
}

.shake-object {
  animation: shake 1.5s infinite;
}

.event-to-copy {
  background-color: var(--ctf-seventh) !important;
}

.right-click-menu {
  li {
    padding: 10px 0;

    &:hover {
      background-color: var(--primary);
      color: white;
    }
  }

  z-index: 99999999;
  font-size: 14px;
  background-color: #fff;
  border-radius: 2px;
  padding: 5px 0 5px 0;
  width: 150px;
  height: auto;
  margin: 0;
  /* use absolute positioning  */
  position: absolute;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  opacity: 1;
  transition: opacity 0.5s linear;
}

.copy-mode-settings {
  &.show-settings {
    top: 0px;
    cursor: grab;
  }

  z-index: 99999999999999999;
  border-radius: 0px 0 7px 7px;
  transition: top 500ms;
  position: fixed;
  right: 45%;
  top: -1200px;
  background-color: var(--ctf-seventh);
  padding: 15px;
  width: 300px;
}

.member-header {
  border-radius: 0.25rem;
  width: 1470px;
  background-color: white;
  margin-left: 4%;
  margin-top: 7%;
  margin-bottom: 2%;
  height: 133px;
}

.member-info-tab {
  margin-left: 2%;
  margin-top: 1%;
  max-height: 700px;
}

.note-div {
  min-height: 153px;
}

.edit-button-member {
  position: relative;
  min-height: 100px;
  overflow: auto;
}

.bottom-btn {
  position: absolute;
  bottom: 30px;
}

.member-info {
  background-color: white;
  border-radius: 0.25rem;
  width: 1470px;
  // background-color: white;
  margin-left: 4%;
  max-height: 700px;
  // height: 740px;
}

.class-add-btn-times {
  width: 180px;
  font-size: 20px;
  background-color: transparent;
  color: black;
  border: none;
  margin-left: -7%;
  margin-top: 6%;
}

.product-add-category {
  width: 180px;
  font-size: 30px;
  background-color: transparent;
  color: #999999;
  border: none;
  margin-top: 5%;
}

.add-product-category {
  // color: #4d4467;
  padding-left: 15px;
  font-size: var(--ctf-h4);
  padding: 0px;
  height: 56px;
  outline: transparent !important;
  box-shadow: none;
  color: #999999;
  font-weight: 500;
}

.class-delte-btn-times {
  width: 51px;
  font-size: 26px;
  background-color: transparent;
  color: red;
  border: none;
  margin-left: -7%;
  margin-top: 6%;
}

.add-other-time {
  font-size: 16px;
  color: #4d4467;
  padding-left: 13px;
}

.member-action-btns {
  font-size: 12px;
  width: 144px;
  font-weight: 600;
}

.member-action-:hover {
  background-color: transparent;
}

.member-action-btn-div {
  margin-top: 12%;
  margin-right: 11%;
}

.light-orange-bg {
  background-color: #ffb835;
}

.over-popup-container {
  max-height: 75px;
  overflow-y: scroll;
  width: 90%;

  input[type="time"]::-webkit-calendar-picker-indicator {
    color: white;
  }

  input[type="time"] {
    appearance: none;
    -webkit-appearance: none;
    background-color: #ffb835;
    border: none;
    color: white;
    font-size: 12px;

    &:hover {
      border: none;
      box-shadow: none;
    }

    &:active {
      border: none;
      box-shadow: none;
    }

    &:focus {
      border: none !important;
      box-shadow: none;
    }
  }
}

.dropdown-menu {
  line-height: 0.6rem;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 6rem;
  padding: 0rem 0;
  margin: 0 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  z-index: 1000;
}

.large-pop-notes {
  max-width: 1200px;
}

.scroll-y {
  overflow-y: scroll;
}

.max-height-150 {
  max-height: 100px;
}

.m-width-80 {
  max-width: 80px !important;
}

.max-width-fit-content {
  max-width: 999px !important;
}

.shrink-4 {
  flex-shrink: 4 !important;
}

.font-size-12 {
  font-size: 12px;
}

.min-width-210 {
  min-width: 210px !important;
}

.weekly-schedule-loader {
  height: 500px;
}

@media screen and (max-width: 600px) {
  .pageCardContainer {
    justify-content: center !important;
  }
}

.date-picker-logo-img {
  width: 25px !important;
}

@media screen and (width <=600px) {
  .minimize-btns button {
    min-width: 45%;
  }

  .number-of-visits-container {
    margin-bottom: 0px !important;
  }

  .pt-mob-2 {
    padding-top: 8px !important;
  }

  .pt-mob-3 {
    padding-top: 16px !important;
  }

  .radio-perdays-package {
    .custom-btn-radio {
      padding-left: 0px !important;
    }
  }

  .selected-times-outer-container {
    padding-left: 18px !important;
  }

  .unlimited-box-container {
    margin-top: 4px !important;
    padding-left: 0px !important;
  }

  .select-box:has(.bg-grey) {
    margin-bottom: 0px !important;
  }

  .custom-btn-radio:has(.no-padding) {
    padding-left: 0;
  }

  .custom-btn-radio:has(.complementary-radio-add-sale) {
    padding-left: 8px !important;
  }

  .no-margin-on-mobile {
    margin: 0px !important;
  }

  #gender-dd-container {
    margin-top: 0px !important;
  }
}

@media only screen and (width > 905px) {
  .select-box:has(.bg-grey) {
    width: 99% !important;
  }
}

@media screen and (width <=1000px) {
  .mobile-d-none {
    display: none !important;
  }
}

.report-top-button-width {
  width: 170px;
}

.report-filter-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  color: #0f172a;
}

.width-25px {
  width: 25px !important;
}

.position-absolute-mobile {
  position: absolute;
  right: 5%;
  top: 18%;
}

@media screen and (max-width: 991px) {
  .date-picker-wrapper .react-datepicker__input-container input {
    max-width: 160px !important;
  }
}

@media screen and (min-width: 991px) {
  .width-100-on-large {
    width: 100% !important;
  }
}

.reports-table {
  .rdt_TableHeadRow {
    background: var(--primary);
    color: white;
    border-radius: 5px 5px 0 0;
  }
}

.financial-table {
  .rdt_TableHeadRow {
    background: white;
    color: black;
    border-radius: 5px 5px 0 0;
    font-size: 16;
  }

  .rdt_TableHead {
    font-size: 15;

  }

}

.DatePicker__input {
  background: #fff;
  border: 1px solid #ddd;
  padding: 0.4em 0.8em;
  font-family: inherit;
  text-align: left;
  font-size: 16px;
  height: 55px !important;
  width: 133% !important;
}

.members-table {
  .rdt_TableHeadRow {
    background: #475569;
    color: white;
    border-radius: 5px 5px 0 0;
  }

  .rdt_TableCell {
    position: relative;
    /* Ensure relative positioning for the cell */
    overflow: visible;
    /* Allow the dropdown to overflow the cell */
  }

}



.member-table-repo {
  position: relative;
  width: 97% !important;
  border-radius: inherit;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0;
}

.btn-no-border {
  border: none;
  background: transparent;
  font-size: 12px;
  color: #28a745;
  font-weight: bold;
  font-family: var(--font-family);
  text-transform: uppercase;
  padding: 8px;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: transparent;
    color: var(--ctf-seventh);
    border-color: var(--ctf-seventh);
  }
}
.rmsc .dropdown-container {
  margin-top: 9px;
  position: relative;
  outline: 0;
  background-color: var(--rmsc-bg);
  border: 1px solid var(--rmsc-border);
  border-radius: var(--rmsc-radius);
}
.btn-no-border-edit {
  border: none;
  background: transparent;
  font-size: 12px;
  color: #3a3b3f;

  &:hover {
    background-color: transparent;
  }
}

#toogle-btn-edit {
  color: #fff;
  background-color: transparent;
  border-color: #4e555b;
}

.member-tabs {
  a {
    color: black;
    font-size: 12px;
    // margin-left: 2%;
  }
}

.btn-no-border-danger {
  color: #a72828;

  &:hover {
    background-color: transparent;
    color: var(--ctf-seventh);
    border-color: var(--ctf-seventh);
  }
}

.user-profile {
  display: flex;
  align-items: center;
  margin-top: 2%;
  margin-left: 2%;
}

.user-image {
  width: 93px;
  height: 93px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name {
  font-size: 18px;
  font-weight: bold;
}

.settle-btn {
  width: 68px;
  height: 26px;
  font-size: 10px;
  text-align: center;
  line-height: 0.5;
}

.text-select-container {
  position: relative;
  display: flex;
  justify-content: center;

  input[type="number"] {
    width: 20%;
    position: absolute;
    height: 50%;
    left: 70%;
    top: 20%;
  }

  .custom-select {
    width: 100%;
    font-size: 16px;
  }
}

// check-in page

.new-filter-wrapper {
  justify-content: flex-start;

  .button-group-toggler {
    background: #f5f7f8;
    max-width: fit-content;
    border-radius: 5px;

    .button-group-inner-container {
      // max-width: fit-content;
      padding: 4px;

      .single-button-container {
        &:first-child {
          .checkin-type-button {
            border-radius: 5px 0px 0px 5px;
          }
        }

        &:last-child {
          .checkin-type-button {
            border-radius: 0px 5px 5px 0px;
          }
        }
      }

      .checkin-type-button {
        padding: 14px 24px;
        border: none;
        box-shadow: none;
        background: transparent;
      }

      .selected-checkin-btn {
        background: #f6a51f;
        color: white;
        font-weight: 600;
        border-radius: 5px !important;
        max-height: calc(100% - 2px);
        position: relative;
        top: 1px;
        transition: background 400ms ease-in, color 400ms ease-in,
          font-weight 400ms ease-in;
      }
    }
  }

  .new-search {
    .search-input {
      input {
        border: 1px solid transparent;
        padding: 1.6rem 0.75rem;
        border-radius: 0.3rem;

        &:focus {
          border: 1px solid var(--primary);
        }
      }
    }
  }
}

.results-count {
  height: 50px;

  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    /* identical to box height, or 150% */

    color: #000000;
  }
}

// collapse sidebar

.collapse-icon {
  position: absolute;
  right: -20px;
  top: 5%;
  z-index: 10000;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .collapse-icon {
    display: none;
  }
}

.h-100 {
  height: 100vh;
}

.color-primary {
  color: var(--primary);
}



.full-height {
  height: 100%;
}

.floating-container {
  position: fixed;
  z-index: 99999999;
  right: 30px;
  bottom: 40px;

  .dropdown-menu {
    // height:80px;
    // overflow-y: scroll;
    left: -50px !important;
    top: 0px !important;
  }

  .floatingDropUp {
    width: 53px;
    height: 53px;
    border-radius: 50%;
    background-color: var(--ctf-seventh);
    border: none;
  }
}

.notification-floating-indicator {
  width: 25px;
  height: 25px;
  position: absolute;
  z-index: 900;
  right: 0;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.inner-notification-indicator {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.payment-checkbox {
  position: absolute;
  margin-top: 0.1rem;
  margin-left: -1.25rem;
}

.payment-checkbox:checked+.checkbox-label {
  background-color: #3f51b5;
  /* Change this to the desired color */
  border-color: #3f51b5;
  /* Change border color when checked if needed */
}

.multi-select {
  width: 200px !important
}
.print-icon {
  margin-left: 52px;
  margin-right: 3rem;
  width: 34px;
  display: inline-block;
  margin-top: 10px;
}
@media print {
  body * {
    visibility: hidden;
  }

  .barcode-container,
  .barcode-container * {
    visibility: visible;
  }

  .print-icon {
    margin-left: 52px;
    width: 34px;
    display: inline-block;
    margin-top: 10px;

  }
}
.print-icon {
  margin-left: 52px;
  margin-right: 3rem;
  width: 34px;
  display: inline-block;
  margin-top: 10px;

}
.dark-blue-table {
  .rdt_TableHeadRow {
    background: var(--ctf-dark-blue) !important;
    color: var(--ctf-white);
    border-radius: 0.6rem 0.6rem 0 0 !important;
    font-weight: 600;
  }

  .rdt_Table {
    border: 1px solid #e0e7f0;
    border-radius: 1rem 1rem 0.2rem 0.2rem;
  }

  .rdt_TableHeader {
    font-weight: 600;
  }

  .rdt_TableCell {
    position: relative;
    /* Ensure relative positioning for the cell */
    overflow: visible;
    /* Allow the dropdown to overflow the cell */
    color: var(--primary);
    font-size: 0.75rem;
    font-weight: 500;
  }
 
}
#permission-category {
  font-size: 14px !important;
}