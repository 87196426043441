/*up to width 1550px*/
@media screen and (max-width:1550px) {
  :root {
    /*font-size root*/
    --font-family: 'Montserrat', sans-serif;
    --ctf-h1: 32px;
    --ctf-h2: 22px;
    --ctf-h3: 18px;
    --ctf-h4: 16px;
    --ctf-h5: 14px;
  } 
  /* contact us options */
  .option .big-58 {
    font-size: 45px;
    line-height: 60px;
  }
  .plan-list-block .plan-list-element {
    width: 33%;
  }
  .rdw-link-modal {
    top: 35px;
    left: auto!important;
    right: 0px;
  }
  .rdw-colorpicker-modal{
    left: auto!important;
    right: 0px;
  }
  .rdw-editor-toolbar{
    justify-content: center;
  }
}

@media screen and (max-width: 1399px)
{
.rdw-link-modal {
    top: 35px;
    left: 35px!important;
    right: 0px;
}
}
/*up to width 1199px*/
@media screen and (max-width:1199px) {

  .panel {
    padding: 25px 25px;
  }
  /* contact us options */
  .option .big-58 {
    font-size: 35px;
    font-weight: bold;
    line-height: 50px;
  }
  .option .title-block {
    padding-left: 0px;
  }

  .admin-layout-body{
    width:100%;
    height: calc(100vh - 86px);
    margin-left: 0px;
  }
  .sidebar {
    width: 300px;
    left: -310px;
    box-shadow: 0px 6px 5px 5px rgba(221,221,221,1);
  }

  .sidebar .close-icon-sidebar{
    display: inline-block;
  }
  .sidebar.active{
    left: 0px;
  }

  .plan-list-block .plan-list-element {
    width: 50%;
  }
  /*success ful block*/
  .success-block {
    left: 0px;
    width: 100%;   
  }
  .information-block .data-block .block-2 {
    width: 50px;
    height: 50px;
    padding: 10px;
  }
  .information-block .data-block .block-1 {
    width: calc(100% - 50px);
  }
  .navbar-block .navbar {
    padding: 10px 25px;
  }
  .sidebar .sidebar-wrapper .list-element.active .curve ,.sidebar .sidebar-wrapper .list-element.active .curve-2  {
    display: none;
  }
}

/*up to width 991px*/
@media screen and (max-width:991px) {
  :root {
    /*font-size root*/
    --ctf-h1: 24px;
    --ctf-h2: 20px;
    --ctf-h3: 16px;
    --ctf-h4: 14px;
    --ctf-h5: 12px;
  }
  h1,.h1{
      line-height: 35px;
  }
  h2,.h2{
    line-height: 30px;
  }
  h3,.h3{
      line-height: 24px;
  }
  h4,.h4{
      line-height: 20px;
  }

  .panel {
    padding: 25px 15px;
}

  /*contact us page */
  .contactus .btn-block .btn {
    margin: 0 10px;
  }
  .btn-large {
    min-width: 280px;
  }


  /* options */
  .option .contact-title{
    text-align: center!important;
  }
  .option .title-block .text-left{
    text-align: center!important;
  }


  .selction-box .container-blk{
      font-size: 14px;
  }

  /*date-picker */
  /*profile date  */

  .large-pop.modal-dialog {
    max-width: 700px;
  }


  .information-block .card-panel{
    margin-bottom: 15px;    
  }
  .chart-panel{
    margin-bottom: 15px;
    height: calc(100% - 15px);
  }
  .navbar-block .navbar {
    padding: 10px 15px;
  }

  
}
/*up to width 767px*/
@media screen and (max-width:767px) {
  :root {
    /*font-size root*/
    --ctf-h1: 20px;
    --ctf-h2: 18px;
    --ctf-h3: 14px;
    --ctf-h4: 13px;
  }
  /*form css*/
  .custom-inputs {
    margin-bottom: 25px;
  }
  .custom-inputs .form-control {
    padding: 10px;
    height: 42px;
  }
  .custom-inputs .PhoneInputInput{
    padding:10px;
    height: 42px;
    padding-left: 40px;
  }
  .custom-inputs textarea.form-control {
    height: 125px;
  }
  .social-link-input .form-control {
    padding-left: 45px;
  }
  /*contact us page*/
  .contactus .contact-title {
    padding: 40px 0px;
    text-align: center;
  }
  .contactus .btn-block {
    padding: 20px 0px;
  } 
  .contect-body .contact-input-main .wrapper-block {
    padding: 25px 15px;
  }
  .contect-body .contact-input-main .wrapper-block .form-title {
    padding-bottom: 15px;
  }
  .contect-body .contact-input-main .wrapper-block .contect-space {
    padding: 10px 0px;
  }
  .contactus .btn-block .btn {
    margin: 10px 10px;
  }

/*options css */
.option .big-58 {
  font-size: 24px;
  line-height: 34px;
}

.action-btn-block .btn:first-child {
  margin: 15px auto;
}

.priviledge-checkbox-list-block .priviledge-checkbox-element {
    width: 50%;
}
.adding-popup .profile-image-links,
.profile-image-links {
  text-align: center;
}
.date-picker-wrapper .react-datepicker__input-container input {
  padding: 10px 15px;
  max-width: 150px;
}

.pagination-inner-block {
  display: block;
  width: 100%;
  text-align: center;
}

.large-pop.modal-dialog {
  max-width: 100%;
}
  .navbar-block .navbar {
    padding: 10px 15px;
  }
  .line-chart {
    padding: 15px;
  }
  .table-filter-header .filters-main-wrapper .filter-list-wraper .filter-list-element {
    margin: 0 10px;
  }
  .under-construction-img{
    width: 150px;
  }
  .common-terms-import-panel {
    height: calc(100vh - 295px);
    min-height: 595px;
}
}
/*up to width 575px*/
@media screen and (max-width:575px) {
 
.action-btn-block {
    text-align: center;
}
.priviledge-checkbox-list-block .priviledge-checkbox-element {
    width: 100%;
}
.pagination-inner-block .row-par-element {
  padding: 5px 10px;
}
.date-picker-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
 }
 .date-picker-wrapper .date-title-block{
   margin-right: 0;
   margin-bottom: 15px;
 }
 .date-picker-wrapper .start-date {
   margin-right: 0px;
   margin-bottom: 15px;
   display: flex;
 }
  .date-picker-wrapper .end-date {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .date-picker-wrapper  .date-picker-img{
     padding-left: 10px;
  }

  .table-filter-header .filters-main-wrapper .filter-list-wraper {
    display: block;
    margin: 0 auto;
    text-align: center;
    align-items: normal;
  }
  .table-filter-header .filters-main-wrapper .filter-list-wraper .filter-list-element {
    margin: 0 0px;
    display: block;
    width: 100%;
  }
  .table-filter-header .filters-main-wrapper .filter-list-wraper .search-block,
  .table-filter-header .filters-main-wrapper .filter-list-wraper .select-filter-block
  {
    text-align: left;
  }
  .table-filter-header .filters-main-wrapper .filter-list-wraper .sort-filter-block{
    display: inline-block;
    width: auto;
    vertical-align: middle;
    margin-right: 15px;
  }
  .table-filter-header .filters-main-wrapper .filter-list-wraper .dropdown.filter-list-element{
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  
  .plan-list-block .plan-list-element {
    width: 100%;
  }
  .adding-popup .card-panel{
    padding: 0;
  }
  .card-panel {
    padding: 12px;
  }
  .card-panel .links {
    padding: 10px 6px;
  }
  .btn-small {
    min-width: 100px;
  }
  .chart-panel{
    height: auto;
  }
  .rdw-colorpicker-modal {
    left: 5px!important;
    right: auto!important;
  }
  .rdw-link-modal  
  {
    left: 5px!important;
    right: auto!important;
  }
  .rdw-image-modal {
    width: 200px!important;
    top: 35px!important;
    left: -21px!important;
  }
  .rdw-embedded-modal ,.rdw-emoji-modal{
    position: absolute;
    width: 180px!important;
    left: -30px !important;
  }
  .rdw-image-modal-btn {
    margin: 0 3px!important;
  }
}
@media screen and (max-width:399px) {
  .rdw-colorpicker-modal {
    right: 5px!important;
    left: auto !important;
  }
  .rdw-link-modal  
  {
    right: 5px !important;
    left: auto !important;
  }
  .rdw-image-modal {
    right: -117px;
  }
  .rdw-embedded-modal, .rdw-emoji-modal {
    
    left: 2px !important;
}
}

@media screen and (max-width:374px) {
  .btn-large {
    min-width: 240px;
  }
}


@media screen and (max-width:349px) {
  .rdw-colorpicker-modal {
   right: 5px!important;
    left: auto!important;
  }
  .rdw-link-modal  
  {
    left: 5px!important;
    right: auto!important;
  }
  .rdw-image-modal {
  
    left: -104px!important;
  }
  .rdw-embedded-modal{
    left: -27px !important;
  }
  .rdw-emoji-modal {
    left: -52px !important;
  }
}

